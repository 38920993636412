.header {
  height: 50px;
  background-color: rgba(255,255,255,.95);
  box-shadow: 0 0 0 1px rgba(0,0,0,.05);
  z-index: 550;

  .row {
    height: 100%;
    padding: 0 20px;
  }
  h1 {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    line-height: 45px;

    span {
      padding: 4px 0;
      display: inline-block;
      position: relative;
    }
  }
  a {
    display: inline-block;
    text-decoration: none;
    color: #13141a;
  }
  .main_nav {
    float: left;
  }
  .sub_nav {
    float: right;

    button {
      height: 100%;
      padding: 0 14px 0 0;
      position: relative;
      background: transparent;
      border: none;
      font-size: 14px;

      &:after {
        content: '';
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: url(https://cf.realclass.co.kr/qualson/images/bt_dropdown_m.png) no-repeat right center;
        background-size: 10px auto;
      }
      &.on {
        color: #2bde73;

        &:after {
          background-image: url(https://cf.realclass.co.kr/qualson/images/bt_dropdown_m_on.png);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }
  .main_nav,
  .sub_nav {
    font-size: 0;

    &>* {
      height: 50px;
      display: inline-block;
      vertical-align: middle;
    }
    .sub_nav_login{
      font-size: 13px;
      line-height: 50px;
    }
  }
  .bt_toggle {
    width: 16px;
    height: 50px;
    background: transparent url(https://cf.realclass.co.kr/qualson/images/bt_menu_m.png?20181105) no-repeat center;
    background-size: 100% auto;
    border: none;

    &.on {
      background-image: url(https://cf.realclass.co.kr/qualson/images/bt_menu_m_on.png?20181105);
    }
  }
  .logo {
    margin-left: 18px;

    a {
      width: 95px;
      height: 50px;
      display: block;
      background: url(https://cf.realclass.co.kr/qualson/images/realclass_logo_m.png?20181105) no-repeat center;
      background-size: 100% auto;
    }
  }
  .btn_group {
    button {
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:active {
        background-color: transparentize(#fff,.3);
      }
    }
    a {
      text-decoration: none;
      display: block;
      line-height: 45px;
    }
  }
  .sub_lnb {
    display: none;
    position: absolute;
    bottom: 0;
  }
  .bt_package {
    margin-left: 24px;
    padding-top: 15px;
    font-size: 13px;

    &.on {
      color: #2bde73;

      span {
        &:before,
        &:after {
          background-color: #2bde73;
        }
      }
    }
    span {
      padding: 3px 0 1px;
      position: relative;
      display: inline-block;

      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        background-color: #13141a;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
    }
  }
  .btn_close,
  .btn_menu,
  .btn_back {
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    background-color: transparent;
    border: none;
    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
    }
    &:active {
      background-color: transparentize(#fff,.3);
    }
  }
  .btn_sub_menu {
    width: 50px;
    height: 100%;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 13px;
    color: #fff;
    &:active {
      background-color: transparentize(#fff,.3);
    }
    &.disabled {
      color: rgba(255,255,255,.2);
    }
  }
  .btn_close {
    &:after {
      @include retina-sprite($common-btn-close-m-group);
    }
    &.btn_dark {
      &:after {
        @include retina-sprite($common-btn-close-dark-m-group);
      }
    }
    &:active {
      background-color: transparentize(#fff,.3);
    }
  }
  .btn_menu {
    &:after {
      background: url('https://cf.realclass.co.kr/qualson/images/bt_menu_m.png?20181105') no-repeat center;
      background-size: 16px auto;
    }
  }
  .btn_back {
    &:after {
      @include retina-sprite($common-btn-back-m-group);
    }
  }
}
.slide_class_menu {
  height: 100%;
  width: 100%;
  //display: none;
  padding: 55px 0 45px;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  z-index: 550;
  -webkit-transition: transform .2s;
  -moz-transition: transform .2s;
  -ms-transition: transform .2s;
  -o-transition: transform .2s;
  transition: transform .2s;

  &.show {
    //display: block;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  .inner_wrap {
    width: 100%;
    height: 100%;
    padding: 0 3px;
    //overflow-y: hidden;
    //overflow-x: auto;
  }
  .swiper-wrapper {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    font-size: 0;
  }
  .menu_box {
    width: 50%;
    height: 383px;
    max-height: 100%;
    padding: 14px 15px;
    display: inline-block;
    vertical-align: top;
    background-color: rgba(255,255,255,.95);
    border-radius: 5px;
    border: 1px solid #2bde73;
    font-size: 13px;
    color: #13141a;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
  }
  .lecture_menu {
    width: 76%;
    min-width: 288px;

    li {
      padding-left: 43px;
    }
  }
  h3 {
    display: inline-block;
    font-size: 11px;
    color: #626262;
    font-weight: 400;
    border-bottom: 1px solid;
  }
  ul {
    margin-top: 18px;
  }
  li {
    position: relative;
    font-size: 13px;

    &+li {
      margin-top: 17px;
    }
  }
  .tag {
    min-width: 31px;
    padding: 1px 1px 0;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid;
    border-radius: 10px;
    font-size: 10px;
    text-align: center;
  }
  a {
    position: relative;
    display: block;
    color: inherit;
    text-decoration: none;

    &+a {
      margin-top: 16px;
    }
    &.new {
      &:before {
        content: '';
        width: 22px;
        height: 15px;
        position: absolute;
        top: -11px;
        left: -11px;
        background: url(https://cf.realclass.co.kr/qualson/images/icon_new_m.png) no-repeat center;
        background-size: 100% auto;
      }
    }
  }
  .swiper-pagination {
    bottom: 16px;
  }
  .swiper-pagination-bullet {
    background-color: #fff;
    border: 1px solid #2bde73;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: #2bde73;
  }
}
.slide_main_menu {
  height: 100%;
  width: 100%;
  padding-top: 50px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 550;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: opacity .1s linear, transform .3s linear;
  -moz-transition: opacity .1s linear,transform .3s linear;
  -ms-transition: opacity .1s linear,transform .3s linear;
  -o-transition: opacity .1s linear,transform .3s linear;
  transition: opacity .1s linear,transform .3s linear;
  opacity: 0;
  //visibility: hidden;

  &.show {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    //visibility: visible;
  }

  .smm_x{
    position: absolute;
    right: 17px;
    top: 68px;
    width: 18px;
    z-index: 1;
    padding: 0;
    border: none;
    background: none;
    img{
      width: 100%;
    }
  }

  .scroll_area {
    width: 100%;
    height: 100%;
    padding: 40px 0;
    background-color: rgba(255, 255, 255, 0.95);
    color: #13141a;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
  }
  a {
    display: block;
    color: inherit;
    text-decoration: none;
    line-height: 16px;

    &.on {
      font-weight: 600;
      color: #2bde73;
    }
    span {
      position: relative;
      display: inline-block;
    }
  }
  .gnb {
    font-size: 14px;

    li+li {
      margin-top: 15px;
    }

    li.gnbUnderBar{
      &:after{
        content: '';
        width: 84px;
        height: 1px;
        background: #a4a8ad;
        display: inline-block;
        margin: 10px 0;
        vertical-align: top;
      }
    }
  }
  .lnb {
    margin-top: 5px;
    font-size: 11px;
    &>li{
      padding: 12px 0;
    }
  }
}

.menuClassToggleWrap {
  display: none;
  margin-top: 25px;
  background-color: rgba(247, 249, 250, 0.95);
  padding: 22px 0;
  .menuClassToggleBox {
    display: inline-block;

    .menu_box {
      text-align: left;
      float: left;
      font-size: 12px;

      & + .menu_box {
        margin-left: 20px;
      }

      h3 {
        display: inline-block;
        font-size: 10px;
        font-weight: 500;
        color: #9ea2a6;
        line-height: 14px;
        border-bottom: 1px solid #9ea2a6;
        margin-bottom: 20px;
      }
      &.type2 h3{
        margin-bottom: 12px;
      }

      li + li {
        margin-top: 20px;
      }

      .menuClassBox {

        a {
          padding: 0;
          line-height: 14px;

          & + a {
            margin-top: 16px;
          }
          &:first-child{margin-top: 8px;}
        }
      }

      .tag {
        display: inline-block;
        font-size: 9px;
        color: #13141a;
        width: 26px;
        height: 14px;
        border: 1px solid #13141a;
        text-align: center;
        border-radius: 7px;
      }
    }
  }
}

.gnbBox{
  position: relative;
  &>li{
    padding: 17px 0;
  }
  &:after{
    content: '';
    width: 84px;
    height: 1px;
    background: #a4a8ad;
    display: inline-block;
    margin: 10px 0;
    vertical-align: top;
  }
  .menuClassToggle{
    span{
      padding-right: 14px;
      background: url(https://cf.realclass.co.kr/qualson/images/7662ed25678f9242f33f759f6638279f) right center no-repeat;
      background-size: auto 5px;
    }
  }
  &.mctActive {
    &:after {
      display: none;
    }
    & .menuClassToggle{
      span{
        color: #2bde73;
        background-image: url(https://cf.realclass.co.kr/qualson/images/667c46b64f322170aa4a1f7739d17be5);
      }
    }
    & .menuClassToggleWrap{
      display: block;
    }
    &>li.mctliActive{
      padding-bottom: 0;
    }
  }
}