$primary-color: #000;
$secondary-color: #13141a;
$active-color: #5900fd;
$opacity: 1;
$highlight-color: rgba(43, 222, 115, $opacity);
$sub-color: #00c047;
$dark-green: #05af3c;

$gutter: 40px;
$column: 70px;
$sidebar-width: $gutter * 3 + $column * 3;

$base-size: 14px;
$base-width: 100%;

@import '_common';
@import '_m.header';

body {
  //overflow-x: hidden;
}
.main {
  padding-top: 50px;
}
.app_view {
  .main {
    padding-top: 0;
  }
}
.popup_main_menu {
  .pop_inner {
    background-color: rgba(0, 0, 0, 0.9);
    height: 100%;
    padding-top: 45px;
    text-align: center;
    width: 100%;

    .pop_head {
      height: 45px;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
    }

    .pop_body {
      height: 100%;
      overflow-y: scroll;
      padding: 20px 0;
    }

    .pop_title {
      border-bottom: none;
      color: rgba(255, 255, 255, 0.5);
      font-size: 15px;
      font-weight: 400;
      line-height: 45px;
    }

    .btn_close {
      background-color: transparent;
      border: none;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 40px;

      &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        @include retina-sprite($common-btn-close-m-group);
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.7);
      }
    }

    a {
      color: #fff;
      text-decoration: none;
    }

    .gnb {
      a {
        display: block;
        font-size: 17px;
        padding: 10px 0;

        &:active {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }

      li + li {
        margin-top: 20px;
      }
    }

    .lnb {
      margin-top: 90px;

      a {
        display: block;
        font-size: 13px;
        padding: 5px 0;

        &:active {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }

      li + li {
        margin-top: 25px;
      }
    }
  }
}

.float_view {
  .pop_inner {
    background-color: #fff;
    border-radius: 10px;
    max-width: 295px;
    overflow: hidden;
    width: 80%;
  }

  .pop_body {
    font-size: 14px;

    .message {
      text-align: center;
    }
  }

  .pop_foot {
    .btn_group {
      .row {
        background-color: #f6f7f8;
        display: block;
        width: 100%;

        & + .row {
          border-top: 1px solid #e6e8ea;
        }
      }

      a {
        display: inline-block;
        line-height: 58px;
        text-decoration: none;
      }

      button,
      a {
        background-color: transparent;
        border: none;
        color: #63696b;
        font-size: 15px;
        font-weight: 600;
        height: 59px;
        text-align: center;
        width: 100%;
      }

      .btn_primary {
        color: $sub-color;
      }
    }
  }
}
.popup_primary {
  .pop_body {
    padding: 37px 20px 28px;

    p {
      line-height: 1.3;

      strong {
        display: block;
        font-size: 15px;
        font-weight: 400;
      }
    }

    p + p {
      margin-top: 22px;
    }

    .alignCenter {
      text-align: center;
    }
  }

  .pop_foot {
    background-color: #f6f7f8;

    .col + .col {
      border-left: 1px solid #e6e8ea;
    }
  }

  .btn_group {
    font-size: 0;

    .col {
      display: inline-block;
      width: 49.9%;
    }

    button {
      width: 100%;
    }
  }
}
.popup_signup_request {
  .pop_inner {
    background-color: #fff;
    height: 100%;
    overflow-y: scroll;
    width: 100%;
  }

  h2 {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }

  .pop_body {
    padding: 90px 20px 20px;
    text-align: center;

    h3 {
      font-size: 11px;
      margin-top: 40px;
      text-align: center;

      span {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        display: inline-block;
        padding: 2px;
      }
    }

    ul {
      margin: 43px auto 0;
      max-width: 280px;
    }

    li {
      min-height: 45px;
      padding: 5px 0 0 70px;
      position: relative;
      text-align: left;

      strong {
        display: block;
        font-size: 15px;
        font-weight: 400;
      }

      span {
        color: #86898c;
        font-size: 11px;
      }

      & + li {
        margin-top: 24px;
      }

      &:before {
        content: '';
        left: 5px;
        position: absolute;
        top: 0;
      }
    }

    .real_free {
      &:before {
        @include retina-sprite($common-real-free-m-group);
      }
    }

    .real_event {
      &:before {
        @include retina-sprite($common-real-event-m-group);
      }
    }

    .real_save {
      &:before {
        @include retina-sprite($common-real-save-m-group);
      }
    }

    .btn_login {
      color: $highlight-color;
      display: block;
      font-size: 13px;
      margin: 19px auto 0;
      text-decoration: none;

      strong {
        border-bottom: 1px solid;
        display: inline-block;
      }
    }

    .btn_signup {
      background-color: $highlight-color;
      border: none;
      color: #000;
      display: block;
      font-size: 15px;
      height: 46px;
      line-height: 46px;
      margin: 70px auto 0;
      width: 213px;
    }
  }

  .pop_foot {
    .btn_close_popup {
      background-color: transparent;
      border: none;
      left: 10px;
      position: absolute;
      top: 10px;

      &:after {
        content: '';
        display: inline-block;
        @include retina-sprite($common-btn-close-dark-m-group);
      }
    }
  }
}
.popup_info_level {
  .pop_inner {
    max-height: 90%;
    overflow-y: scroll;
  }

  .message {
    font-size: 16px;
    padding: 35px 10px 25px;

    span + span {
      margin-top: 7px;
    }

    .en_script {
      font-weight: 600;
    }

    .ko_script {
      display: block;
      font-size: 15px;
    }
  }

  .pop_foot {
    .btn_group {
      .row {
        position: relative;
      }

      button {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        padding-left: 75px;
        text-align: left;
      }
    }
  }

  .info_level {
    button {
      strong {
        height: 100%;
        left: 18px;
        position: absolute;
        top: 0;
        width: 51px;

        &:before,
        &:after {
          content: '';
          left: 0;
          position: absolute;
          top: 50%;
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        &:before {
          width: 100%;
          @include retina-sprite($common-bg-level-check-m-group);
        }

        &:after {
          @include retina-sprite($common-bg-level-check-on-m-group);
        }

        &.lv0 {
          &:after {
            width: 0%;
          }
        }

        &.lv1 {
          &:after {
            width: 33.33%;
          }
        }

        &.lv2 {
          &:after {
            width: 66.66%;
          }
        }

        &.lv3 {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
}

.popup_question {
  h2 {
    color: #2bde73;
    font-size: 19px;
    font-weight: bold;
    margin-top: 39px;
    text-align: center;
    text-decoration: underline;
    text-underline-position: under;
  }

  ul {
    margin-left: 20px;
  }

  li {
    color: #000;
    font-size: 13px;
    line-height: 1.5;
    list-style-type: disc;
    margin-bottom: 13px;
    text-align: left;
  }

  button {
    color: #00c047 !important;
  }
}
.popup_absent_remain {
  .pop_head {
    font-size: 15px;
    padding: 18px 0 0;
    text-align: center;
  }

  .pop_body {
    line-height: 1.5;
  }
}
.popup_challenge_info {
  &.scroll_view {
    .inner {
      padding-bottom: 55px;
      padding-top: 55px;
    }
  }

  .btn_close_popup {
    background-color: transparent;
    border: none;
    height: 50px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 40px;

    &:before {
      content: '';
      display: inline-block;
      @include retina-sprite($common-btn-close-gray-m-group);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.09);
    }
  }

  .pop_inner {
    background-color: #fff;
    //width: 550px;
    max-width: 95%;
    position: relative;
  }

  .pop_head {
    border-bottom: 1px solid $highlight-color;
    padding: 15px 0 12px;
    text-align: center;

    h3 {
      font-size: 13px;
    }
  }

  .pop_body {
    font-size: 12px;
    line-height: 1.5;
    padding: 15px 20px 37px;

    h4 {
      border-bottom: 1px solid #828487;
      font-size: 12px;
      margin: 25px 0 6px;
      padding: 0 0 6px;
    }

    ol,
    ul {
      margin-top: 8px;
    }

    li {
      padding-left: 9px;
      position: relative;

      &:before {
        content: '-';
        left: 2px;
        position: absolute;
        top: 1px;
      }

      & + li {
        margin-top: 2px;
      }
    }

    em {
      font-style: normal;
      font-weight: 600;
      position: relative;

      &:before {
        background-color: transparentize(#fff568, 0.5);
        bottom: 0;
        content: '';
        height: 70%;
        left: 0;
        position: absolute;
        width: 100%;
      }

      span {
        position: relative;
      }
    }
  }
}
.slides {
  .controller {
    &.btn_white {
      span {
        @include retina-sprite($common-btn-prev-white-m-group);
      }
    }

    &.btn_gray {
      span {
        @include retina-sprite($common-btn-prev-gray-m-group);
      }
    }

    &.btn_small {
      span {
        @include retina-sprite($common-btn-prev-sm-m-group);
      }
    }
  }
}
.footer {
  background-color: #eceff0;
  color: #878c8d;
  font-size: 11px;
  padding: 22px 0 58px;
  text-align: left;

  .inner {
    padding: 0 20px;
  }

  h5,
  h6 {
    font-weight: 400;
  }

  h5 {
    font-size: 0;

    * {
      font-size: 11px;
    }

    strong {
      font-weight: 500;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    & + h5 {
      margin-top: 4px;
    }
  }

  h6 {
    font-size: 11px;
    margin-top: 10px;

    a {
      color: inherit;
      cursor: pointer;
      text-decoration: none;
    }
  }

  i {
    font-style: normal;
    margin: 0 6px;
  }

  .txt_legal_line {
    border-top: 1px solid #dde0e1;
    margin-top: 14px;
    padding-top: 9px;
  }
}
@media (max-width: 500px) {
  .footer {
    h5 {
      i {
        display: none;
      }

      span {
        display: block;
        padding: 5px 0;
      }
    }
  }
}

.btn_link,
.btn_bg_white,
.btn_bg_green,
.btn_bg_black {
  border: none;
  font-size: 11px;
  height: 36px;
  line-height: 35px;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
}
.btn_link {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    margin-right: 24px;
    vertical-align: middle;
    width: 40px;
  }
}
.link_white,
.link_green,
.link_black {
  &:before {
    margin-right: 24px;
  }
}
.btn_bg_white,
.link_white {
  background-color: #fff;
  color: $highlight-color;

  &:active {
    background-color: #e6e9ea;
  }
}
.btn_bg_green,
.link_green {
  background-color: $highlight-color;
  color: #000;

  &:active {
    background-color: #25ad5c;
  }
}
.btn_bg_black,
.link_black {
  background-color: #000;
  color: #fff;

  &:active {
    background-color: #333;
  }
}
.link_icon_white {
  color: #fff;

  &:before {
    @include retina-sprite($common-btn-white-m-group);
  }
}
.link_icon_green {
  color: $highlight-color;

  &:before {
    @include retina-sprite($common-btn-green-m-group);
  }
}
.link_icon_black {
  color: #000;

  &:before {
    @include retina-sprite($common-btn-black-m-group);
  }
}
.link_white {
  &:before {
    @include retina-sprite($common-btn-green-m-group);
    //background: url('https://djtha9evtgm1n.cloudfront.net/images/btn_white@2x.gif') no-repeat center;
    //background-size: 30px auto;
  }
}
.link_green {
  &:before {
    @include retina-sprite($common-btn-black-m-group);
    //background: url('https://djtha9evtgm1n.cloudfront.net/images/btn_green@2x.gif') no-repeat center;
    //background-size: 30px auto;
  }
}
.link_black {
  &:before {
    @include retina-sprite($common-btn-white-m-group);
    //background: url('https://djtha9evtgm1n.cloudfront.net/images/btn_black@2x.gif') no-repeat center;
    //background-size: 30px auto;
  }
}

.main {
  .floating_banner {
    .inner {
      padding-left: 83px;
      padding-right: 10px;
    }
  }
}
.floating_banner {
  background-color: rgba(240, 240, 240, 0.9);
  bottom: 0;
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.9);
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 600;

  .inner {
    height: 50px;
    position: relative;
    text-align: left;
  }

  .img_area,
  .txt_area {
    display: inline-block;
    vertical-align: middle;
  }

  .img_area {
    bottom: 0;
    height: 100%;
    left: 10px;
    position: absolute;
    width: 73px;

    img {
      bottom: 0;
      height: auto;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  .txt_area {
    color: #000;
    font-size: 12px;
    padding-left: 10px;
    padding-top: 10px;

    b {
      border-bottom: 1px solid;
      display: inline-block;
      margin: 0 4px;
    }

    i {
      border-bottom: 1px solid;
      display: inline-block;
      font-style: normal;
      margin: 0 4px;
    }
  }

  a {
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
@media (max-width: 400px) {
  .floating_banner {
    .txt_area {
      font-size: 11px;
      padding-left: 0;
    }
  }
}

.legal_line {
  bottom: 5px;
  color: #000;
  font-size: 10px;
  left: 50%;
  position: absolute;
  text-align: center;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.popup_refund_info,
.popup_apply_success,
.popup_mission,
.popup_marketing,
.popup_mission2,
.popup_mission3,
.popup_mission_final {
  display: none;

  &.show {
    display: block;
  }

  .pop_inner {
    padding-top: 36px;
    text-align: center;
  }

  .pop_head {
    h2 {
      color: #000;
      font-size: 19.3px;
      font-weight: bold;
    }

    h3 {
      font-size: 20px;
      font-weight: bold;

      &.title-2 {
        color: #5cd99d;
      }

      &.title-3 {
        color: #4acab9;
      }
    }

    h3 + p {
      font-size: 12.3px;
      font-weight: bold;
      margin: 11px auto 15.5px;
    }

    h3.title-2 + p {
      color: #5cd99d;
    }

    h3.title-3 + p {
      color: #4acab9;
    }

    p {
      color: #000000;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.38;
      margin: 21px auto 18.5px;
      width: 206.5px;
    }
  }

  .pop_body {
    padding: 0 19px 21.5px;

    &.no_padding {
      padding: 0;
    }

    p.sub_content {
      background: #f6f7f8;
      color: #9ba3a6;
      font-size: 9.5px;
      font-weight: normal;
      padding: 11px 0 12.5px;
      width: 100%;
    }

    p.marketing_text {
      background: #f6f7f8;
      color: #626569;
      font-size: 12.5px;
      font-weight: normal;
      line-height: 1.2;
      padding: 13.5px 8.5px 13px 11.5px;
      text-align: left;
    }

    p.guide {
      color: #9ba3a6;
      font-size: 12.5px;
      font-weight: normal;
      margin-top: 19px;
    }

    .success-text {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      margin-top: 20.5px;
    }

    .careful {
      color: #9ba3a6;
      font-size: 9.5px;
      font-weight: normal;
      line-height: 1.37;
      padding: 14.5px 20px 12.5px 16.5px;
      text-align: left;
    }

    ul.study_list {
      border-bottom: 1px solid #f6f7f8;
      padding: 20px;
      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        & + li {
          margin-top: 20px;
        }

        &.regular {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: left;

          .head {
            display: flex;
            margin-bottom: 13px;

            .text {
              flex: 1;
              text-align: left;
              word-break: keep-all;
            }
          }
          .content {
            width: 100%;
            .writingContents {
              border: solid 1px #e1e3e6;
              border-radius: 0;
              box-sizing: border-box;
              padding: 5px 10px;
              width: 100%;
            }
          }
        }

        &.flexible {
          .head {
            align-items: flex-start;
            display: flex;
            margin-bottom: 13px;
          }
        }

        .count {
          color: #9ba3a6;
          font-size: 8.8px;
          font-weight: normal;
          position: absolute;
          right: 0;
          top: 17px;
        }

        .btn-submit {
          background: #fff;
          border: 1px solid #e1e3e6;
          color: #e1e3e6;
          font-size: 12px;
          font-weight: bold;
          height: 30px;
          margin: 0 auto;
          padding: 0;
          position: relative;
          width: 100px;

          &.active {
            border: solid 1px #13141a;
            color: #13141a;
          }

          &.completed {
            border: none;
            color: #2bde73;
          }

          &.completed:after {
            background: url('https://cf.realclass.co.kr/qualson/images/c4e3b6af199258ce17ca126eec3a353f');
            background-size: 100% 100%;
            content: '';
            display: block;
            height: 6px;
            position: absolute;
            right: 17px;
            top: 10px;
            width: 7.7px;
          }
        }

        .content {
          position: relative;
          text-align: left;

          textarea {
            -webkit-appearance: none;
            border: solid 1px #e1e3e6;
            border-radius: 0;
            color: #13141a;
            font-size: 12px;
            font-weight: normal;
            height: 86px;
            padding: 10px;
            resize: none;
            width: 100%;
          }

          &.m20 {
            margin-left: 20px;
          }

          input[type='file'] {
            cursor: pointer;
            height: 156px;
            left: 0px;
            opacity: 0;
            position: absolute;
            top: 0px;
            width: 196.5px;
          }

          .wrapCheckLevel {
            padding-left: 28px;

            .btn-level {
              background: #fff;
              border: 1px solid #e1e3e6;
              border-radius: 10.6px;
              color: #e1e3e6;
              display: inline-block;
              font-size: 12.5px;
              font-weight: 500;
              height: 21.5px;
              line-height: 21.5px;
              text-align: center;
              white-space: nowrap;
              width: 60px;

              &.on {
                background: #13141a;
                border: none;
                color: #fff;
              }

              &:nth-child(2) {
                margin: 0 6px;
              }
            }
          }

          .btn-upload {
            background: url('https://cf.realclass.co.kr/qualson/images/a69df8e74bb9fd60673ac78de01d6392');

            background-size: 100% 100%;

            border: none;

            height: 156px;
            position: relative;
            width: 196.5px;
            &:after {
              background: url('https://cf.realclass.co.kr/qualson/images/7d0dbd3e92efe5b8dcd3ae50f0b0c23a');
              background-size: 100% 100%;
              bottom: 0;
              content: '';
              display: block;
              height: 25px;
              position: absolute;
              right: 0;
              width: 82px;
            }
            &.off {
              display: none;
            }
          }

          div.pic {
            background: url('https://cf.realclass.co.kr/qualson/images/6b4ef9547d72a61d95b39c1c191be7b2');

            background-size: 100% 100%;

            display: none;

            height: 101.3px;
            position: relative;
            width: 100.7px;
            &.on {
              display: block;
            }
            &.on:after {
              background: url('https://cf.realclass.co.kr/qualson/images/7a5f04f260d909e238588e064dd1c6b4');
              background-size: 100% 100%;
              bottom: -10px;
              content: '';
              display: block;
              height: 22.7px;
              position: absolute;
              right: -10px;
              width: 23px;
            }
          }
        }
        .wrapCheckStar {
          padding-left: 28px;

          .star {
            background: url('https://cf.realclass.co.kr/qualson/images/dbb782f00dd48b544073e1cb8cea0e3e');
            background-size: 100% 100%;
            display: inline-block;
            height: 24px;
            width: 25.7px;

            &.on {
              background: url('https://cf.realclass.co.kr/qualson/images/1c4861138571e98c78c437d05c13b372');
              background-size: 100% 100%;
            }
          }
        }

        .icoIndex {
          background-color: #5cd99d;
          border-radius: 10px;
          color: #fff;
          font-style: normal;
          height: 20px;
          line-height: 20px;
          margin-right: 10px;
          width: 20px;
        }

        .ico-check {
          background: url('https://cf.realclass.co.kr/qualson/images/b94bb8e344bdd8704eeff22f0c9c9871');
          background-size: 100% 100%;
          display: inline-block;
          height: 18px;
          margin-right: 10px;
          width: 18px;

          &.on {
            background: url('https://cf.realclass.co.kr/qualson/images/128ba5d03efb592f6b5f1af0e330c0bf');
            background-size: 100% 100%;
          }
        }

        .btn-group {
          align-items: center;
          display: flex;
          text-align: left;

          span.btn {
            display: inline-block;
            height: 23px;
            margin-right: 7.5px;
            width: 46px;
          }

          .btn-voice {
            background: url('https://cf.realclass.co.kr/qualson/images/259653dcd66a5e0494da24176eb1a613');
            background-size: 100% 100%;

            &.on {
              background: url('https://cf.realclass.co.kr/qualson/images/e0d26776b7640d0ceb5baf035551120e');
              background-size: 100% 100%;
            }
          }

          .btn-play {
            background: url('https://cf.realclass.co.kr/qualson/images/2b95fd452f66ef2d9bffd9a1026fa31e');
            background-size: 100% 100%;

            &.on {
              background: url('https://cf.realclass.co.kr/qualson/images/92ec9555e7a076c1892f66007f82d790');
              background-size: 100% 100%;
            }

            &.stop {
              background: url('https://cf.realclass.co.kr/qualson/images/b390a00d137f68c426f1233d9032bc92');
              background-size: 100% 100%;
            }
          }

          .time {
            bottom: 3px;
            color: #2bde73;
            font-size: 12.5px;
            font-weight: normal;
            left: 51px;
            position: absolute;

            &.record {
              color: #ff414d;
            }

            &.play {
              color: #2bde73;
            }
          }

          .ico {
            display: inline-block;
            height: 13px;
            margin-left: 9px;
            width: 15px;

            &.play {
              background: url('https://cf.realclass.co.kr/qualson/images/bd33639e767b311a0d4bfe2a657379b0');
              background-size: 100% 100%;
            }

            &.record {
              background: url('https://cf.realclass.co.kr/qualson/images/72ce4715dbafd36494b5187ed64a1885');
              background-size: 100% 100%;
            }
          }
        }
      }
    }

    ul.info_list {
      li {
        background: #f6f7f8;

        display: flex;
        padding: 10px 20px 7px;
        width: 256.5px;
        & + li {
          margin-top: 10px;
        }

        p {
          margin-left: 8.5px;
          text-align: left;
        }
      }
    }

    ul.mission_list {
      margin-top: 22.5px;
      padding-left: 25px;

      li {
        color: #000;

        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        list-style-type: disc;
        text-align: left;
        & + li {
          margin-top: 16px;
        }
      }
    }
  }

  .btn_group {
    button {
      color: #2bde73 !important;
    }
  }
}

.pic-swiper {
  padding: 0 21px;
}

.pic-swiper-container {
  &.swiper-container {
    height: 100%;
    width: 100%;
  }

  .swiper-wrapper {
    display: flex;

    .swiper-slide {
      & + .swiper-slide {
        margin-left: 18px;
      }

      .swiper-slide-item {
        height: 156px;
        position: relative;
        width: 196.5px;

        &.rect {
          height: 180px;

          width: 180px;
        }

        .swiper-content {
          position: relative;
          text-align: left;

          textarea {
            -webkit-appearance: none;
            border: solid 1px #e1e3e6;
            border-radius: 0;
            color: #13141a;
            font-size: 12.5px;
            font-weight: normal;
            height: 86.5px;
            margin-top: 3px;
            padding: 12px 4.5px 8px 13px;
            resize: none;
            width: 254.5px;
          }

          &.m20 {
            margin-left: 20px;
          }

          input[type='file'] {
            cursor: pointer;
            height: 156px;
            left: 0px;
            opacity: 0;
            position: absolute;
            top: 0px;
            width: 196.5px;
          }

          .btn-level {
            background: #fff;
            border: 1px solid #e1e3e6;
            border-radius: 10.6px;
            color: #e1e3e6;
            display: inline-block;
            font-size: 12.5px;
            font-weight: 500;
            height: 21.5px;
            line-height: 21.5px;
            text-align: center;
            white-space: nowrap;
            width: 60px;

            &.on {
              background: #13141a;
              border: none;
              color: #fff;
            }

            &:nth-child(2) {
              margin: 0 6px;
            }
          }

          .btn-upload {
            background: url('https://cf.realclass.co.kr/qualson/images/a69df8e74bb9fd60673ac78de01d6392');

            background-size: 100% 100%;
            border: none;
            height: 156px;
            width: 196.5px;
            &.off {
              display: none;
            }

            &:after {
              background: url('https://cf.realclass.co.kr/qualson/images/7d0dbd3e92efe5b8dcd3ae50f0b0c23a');
              background-size: 100% 100%;
              bottom: 50%;
              content: '';
              display: block;
              height: 25px;
              margin-bottom: -12.5px;
              position: absolute;
              right: -41px;
              width: 82px;
              z-index: 100;
            }
          }

          div.pic {
            background-size: 100% 100%;

            display: none;

            height: 156px;

            position: relative;
            width: 196.5px;
            &.on {
              display: block;
            }
            //background: url('https://cf.realclass.co.kr/qualson/images/6b4ef9547d72a61d95b39c1c191be7b2');
            &.on:after {
              background: url('https://cf.realclass.co.kr/qualson/images/7a5f04f260d909e238588e064dd1c6b4');
              background-size: 100% 100%;
              bottom: -10px;
              content: '';
              display: block;
              height: 22.7px;
              position: absolute;
              right: -10px;
              width: 23px;
            }
          }
        }
      }
    }
  }
}
