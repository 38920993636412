@import "_m.base";
@import "_account";

$base-size: 13px;

.leave_page {
  .header {
    height: 45px;
    background-color: #000;
    border-bottom: none;
  }
}
.header {
  .btn_send {
    width: 50px;
    height: 100%;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    color: $highlight-color;

    &.disabled {
      color: rgba(255,255,255,.2);
      &:active {
        background-color: transparent;
      }
    }
  }
}
.page_sub_header {
  position: relative;
  background-color: #fafafa;

  h2 {
    padding: 0 20px;
    height: 46px;
    font-size: 13px;
    color: #626569;
    line-height: 46px;
  }
  .sub_button {
    width: 62px;
    height: 25px;
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 11px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid;
    line-height: 23px;
    text-align: center;
    color: #000;
    text-decoration: none;
  }
  .row {
    height: 46px;
  }
}
.classic_login_info {
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  margin: 20px 0 0;
  padding: 0 24px;
  color: #434449;
  font-size: 11px;
  img {
    width: 42px;
    margin-right: 10px;
  }
}
.account_form {
  padding: 28px 20px;

  .progress {
    span {
      height: 2px;
      position: fixed;
      top: 45px;
      left: 0;
      display: none;
      background-color: #3ad478;

      &.on {
        display: block;
      }
    }
    .step1 {
      width: 50%;
    }
    .step2 {
      width: 100%;
    }
  }
  .sub_title {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    font-size: 12px;
    font-weight: 400;
    color: #bcc2c9;

    .warning_message {
      padding-left: 12px;
    }
  }
  .mobile_check_row {
    padding-right: 83px;
    .btn_check {
      height: 34px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .input_area {
    label {
      padding-top: 22px;
      position: relative;
      display: block;
      &+label {
        margin-top: 40px;
      }
    }
    input {
      width: 100%;
      padding: 6px 2px;
      font-size: 16px;
      border: none;
      border-bottom: 1px solid #bcc2c9;
      border-radius: 0;
    }
    .on,
    .active,
    .warning {
      &+.sub_title {
        display: block;
      }
    }
    .warning {
      &+.sub_title {
        .warning_message {
          display: inline-block;
        }
      }
    }
    .info_message {
      display: none;
      font-size: 12px;
      font-weight: 400;
      color: $highlight-color;
      position: absolute;
      left: 0;
      bottom: -3px;
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
      -o-transform: translateY(100%);
      transform: translateY(100%);

      &.on {
        display: block;
      }
    }
    .warning {
      border-bottom: 1px solid #ff414d;
    }
    .active {
      border-bottom: 1px solid $highlight-color;
      &+.sub_title {
        color: $highlight-color;
      }
    }
  }
  .warning_message {
    display: none;
    color: #ff414d;
  }
  .txt_description {
    margin-top: 37px;
    font-size: 13px;
    text-align: center;

    a {
      color: #000;
      text-decoration: underline;
    }
    strong {
      color: $highlight-color;
      font-weight: 400;
    }
  }
  .btn_send {
    width: 100%;
    max-width: 217px;
    height: 45px;
    display: block;
    margin: 40px auto 0;
    padding: 0;
    font-size: 14px;
    color: $highlight-color;
    border: 1px solid #13141a;
    background-color: #13141a;

    &.disabled {
      color: #bcc2c9;
      border: 1px solid #bcc2c9;
      background-color: transparent;
    }
  }
}
.login_form {
  .rel_account {
    margin-top: 46px;

    p {
      text-align: right;
    }
    p+p {
      margin-top: 30px;
    }
    a {
      padding: 1px 0;
      display: inline-block;
      color: #000;
      text-decoration: none;
      border-bottom: 1px solid;

      &:after {
        content: '';
        margin-left: 5px;
        display: inline-block;
        vertical-align: middle;
        @include retina-sprite($common-btn-login-rel-m-group);
      }
    }
  }
}
.find_form {
  h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;

    span {
      display: block;
      font-size: 14px;
      color: #bcc2c9;
      &:after {
        content: '';
        width: 22px;
        height: 1px;
        display: block;
        margin: 12px auto 9px;
        background-color: #bcc2c9;
      }
    }

  }
  .result_id {
    padding-top: 22px;
    text-align: center;

    .btn_group {
      margin-top: 42px;
    }
  }
  .result_id,
  .result_password {
    a {
      width: 215px;
      height: 45px;
      margin: 0 auto;
      display: block;
      color: $highlight-color;
      font-size: 15px;
      line-height: 43px;
    }
  }
  .result_password {
    padding-top: 58px;
    text-align: center;

    .btn_group {
      margin-top: 45px;
    }
  }
}

.leave {
  h2 {
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    line-height: 1.4;
  }
  .btn_group {
    text-align: center;
    margin-top: 30px;

    button {
      text-align: center;
    }
  }
  .check_list {
    .row {
      font-size: 16px;
    }
  }
  h3 {
    font-weight: 400;
    text-align: center;
  }
}

.btn_check {
  color: #fff;
  background-color: #000;
  font-size: 11px;
  border: none;

  &.disabled {
    background-color: #bcc2c9;
  }
}
.btn_normal {
  width: 215px;
  height: 46px;
  display: inline-block;
  font-size: 15px;
  color: $highlight-color;
  text-decoration: none;
  border: 1px solid $highlight-color;
  line-height: 46px;
}

.popup_alert {
  .pop_body {
    padding: 29px 5px 30px;
  }
  .message {
    line-height: 1.3;
    strong {
      display: block;
      margin-bottom: 14px;
      font-size: 18px;
    }
    i {
      margin-top: 3px;
      font-style: normal;
      font-size: 14px;
      display: block;
    }
  }
  .pop_foot {
    .btn_group {
      .col {
        display: block;
        background-color: #f6f7f8;
        &+.col {
          border-top: 1px solid #e6e8ea;
        }
      }
      button,a {
        &:active {
          background-color: #dadbdc;
        }
      }
    }
  }
}

.signup_request_page {
  background-color: #fff;
  .header {
    position: absolute;
    background-color: transparent;
    box-shadow: none;

    button {
      height: 50px;
    }
  }
  .main {
    padding-top: 0;
  }
  .main, .main>div {
    height: 100%;
  }
  .main>div {
    //padding-top: 48px;
  }
  h2 {
    font-size: 18px;
    text-align: center;
  }
  .page_footer {
    padding: 72px 0 37px;
    text-align: center;

    button {
      width: 213px;
      height: 46px;
      background-color: $highlight-color;
      color: #000;
      border: none;
      font-size: 15px;
    }
    a {
      display: block;
      margin: 19px auto 0;
      font-size: 13px;
      color: $highlight-color;
      text-decoration: none;

      strong {
        display: inline-block;
        border-bottom: 1px solid;
      }
    }
  }
}
.content_group {
  //padding: 0 20px;
  h2, h3 {
    line-height: 1.3;
  }
  h3 {
    margin-top: 24px;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    span {
      padding: 2px;
      display: inline-block;
      border-bottom: 1px solid rgba(0,0,0,.3);
    }
  }
  ul {
    margin: 18px auto 0;
    text-align: center;
  }
  li {
    width: 90px;
    position: relative;
    padding: 55px 0 0 0;
    display: inline-block;
    vertical-align: top;

    span {
      display: inline-block;
      font-size: 12px;
      color: #000;
      line-height: 1.3;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  .top_section {
    position: relative;
  }
  .bottom_section {
    padding: 35px 0;
  }
  .btn_group {
    width: 100%;
    padding-bottom: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;

    .btn_signup {
      width: 210px;
      display: block;
      margin: 0 auto 14px;
      font-weight: 500;
      font-size: 14px;
    }
    .btn_login {
      font-size: 13px;
      color: #ffdce3;
      text-decoration: none;

      strong {
        display: inline-block;
        border-bottom: 1px solid;
        font-weight: 400;
        color: $highlight-color;
        cursor: pointer;
        line-height: 1.2;
      }
    }
  }
  .img_area {
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  .real_free {
     &:before {
       @include retina-sprite($common-real-free-m-group);
     }
  }
  .real_event {
    &:before {
      @include retina-sprite($common-real-event-m-group);
    }
  }
  .real_save {
    &:before {
      @include retina-sprite($common-real-save-m-group);
    }
  }
}
@media (max-width: 620px) {
  .leave {
    .check_list {
      margin-top: 42px;
    }
    h3 {
      font-size: 23px;
    }
  }
}

.marketing-agreement {
  display: flex;
  label {
    //display: flex !important;
    font-size: 14px;
    font-weight: 500;
    color: #585858;
    a {
      text-decoration: underline;
      color: #585858;
    }
  }
  input:checked + label .fake_checkbox {
    background: url('https://cf.realclass.co.kr/qualson/images/d6824e6a2de6323077d66ce79d40bf1e');
    background-size: 100% 100%;
  }
  .fake_checkbox {
    vertical-align: middle;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    background: url('https://cf.realclass.co.kr/qualson/images/e2a9bb15f44bff67f8bcce1d73821521');
    background-size: 100% 100%;
  }
}

.marketing-text {
  margin-top: 22px;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  color: #707276;
}