@charset "UTF-8";
/*! Copyright Qualson inc. */
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("AppleSDGothicNeo"), url(../fonts/AppleSDGothicNeo_light_subset.woff2) format("woff2"), url(../fonts/AppleSDGothicNeo_light_subset.woff) format("woff"); }

@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("AppleSDGothicNeo"), url(../fonts/AppleSDGothicNeo_regular_subset.woff2) format("woff2"), url(../fonts/AppleSDGothicNeo_regular_subset.woff) format("woff"); }

@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("AppleSDGothicNeo"), url(../fonts/AppleSDGothicNeo_bold_subset.woff2) format("woff2"), url(../fonts/AppleSDGothicNeo_bold_subset.woff) format("woff"); }

html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

body {
  margin: 0;
  font-size: 14px;
  font-family: 'AppleSDGothicNeo', AppleSDGothicNeo-Regular, 'Malgun Gothic', '맑은 고딕', dotum, '돋움', 'Apple SD Gothic Neo', sans-serif;
  color: #000; }
  body br {
    font-family: AppleSDGothicNeo-Regular, 'Malgun Gothic', '맑은 고딕', dotum, '돋움', 'Apple SD Gothic Neo', sans-serif; }

* {
  box-sizing: inherit; }
  *:before, *:after {
    box-sizing: inherit; }

ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style: none; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
strong,
span {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default; }

table {
  border-collapse: collapse; }

button {
  border-radius: 0;
  background-color: transparent; }

select,
button {
  cursor: pointer;
  outline: none; }
  select *,
  button * {
    cursor: pointer;
    outline: none; }

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
textarea {
  color: #000; }
  input[type='text']::-webkit-input-placeholder,
  input[type='email']::-webkit-input-placeholder,
  input[type='password']::-webkit-input-placeholder,
  input[type='tel']::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #858a91; }
  input[type='text']::-moz-placeholder,
  input[type='email']::-moz-placeholder,
  input[type='password']::-moz-placeholder,
  input[type='tel']::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #858a91; }
  input[type='text']:-ms-input-placeholder,
  input[type='email']:-ms-input-placeholder,
  input[type='password']:-ms-input-placeholder,
  input[type='tel']:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #858a91; }
  input[type='text']:-moz-placeholder,
  input[type='email']:-moz-placeholder,
  input[type='password']:-moz-placeholder,
  input[type='tel']:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #858a91; }

input[type='text'],
input[type='submit'],
input[type='search'] {
  margin: 0;
  outline: none; }

input[type='submit'] {
  cursor: pointer; }

input:active,
input:focus {
  outline: none; }

a[role='button'] {
  cursor: pointer;
  outline: none; }
  a[role='button'] * {
    cursor: pointer;
    outline: none; }

select::-ms-expand {
  display: none; }

a[role='button'] {
  text-decoration: none;
  color: inherit;
  outline: none; }

img {
  border: none;
  -webkit-touch-callout: none; }

input[type='search']::-webkit-search-decoration, input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-results-button, input[type='search']::-webkit-search-results-decoration {
  display: none; }

select[disabled='disabled'] {
  cursor: default !important; }

.a11y-hidden {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }
  .a11y-hidden.focusable {
    overflow: visible;
    position: static;
    clip: auto;
    width: auto;
    height: auto;
    margin: 0; }

.clearfix:after {
  content: '';
  display: block;
  clear: both; }

.ir {
  text-indent: -9999px;
  overflow: hidden; }

.txtL {
  text-align: left; }

.txtR {
  text-align: right; }

html,
body,
.wrap {
  width: 100%;
  height: 100%; }

.inner {
  width: 100%;
  margin: 0 auto;
  position: relative; }

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 501; }

.modal {
  overflow: hidden; }
  .modal:after {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 502; }

[class^='popup_'] {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 600;
  background-color: rgba(0, 0, 0, 0.2); }
  [class^='popup_'].show {
    display: block; }
  [class^='popup_'] .inner {
    height: 100%; }
  [class^='popup_'].scroll_view {
    overflow-y: scroll; }
    [class^='popup_'].scroll_view .inner {
      height: auto;
      min-height: 100%;
      padding-top: 110px;
      padding-bottom: 110px; }
    [class^='popup_'].scroll_view .pop_inner {
      margin: 0 auto;
      max-width: 100%; }
  [class^='popup_'].float_view .pop_inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); }
  [class^='popup_'] .btn_pop {
    padding: 22px 0 21px;
    background-color: #5900fd;
    color: #fff;
    font-size: 22px;
    border: none; }
    [class^='popup_'] .btn_pop.primary {
      background-color: #aaaeb2; }
  [class^='popup_'] .pop_title {
    border-bottom: 1px solid; }
  [class^='popup_'] .btn_close_popup {
    width: 20px;
    height: 20px;
    border: none; }

.img_round {
  border-radius: 50%;
  overflow: hidden;
  display: inline-block; }
  .img_round img {
    display: block; }

.slides {
  position: relative; }
  .slides .controller.btn_black span {
    background-image: url(../images/sprite/common.png);
    background-position: -1338px -495px;
    width: 30px;
    height: 53px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_black span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .slides .controller.btn_fill span {
    background-image: url(../images/sprite/common.png);
    background-position: -1396px -1221px;
    width: 11px;
    height: 19px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_fill span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .slides .controller.btn_fill:hover span {
    background-image: url(../images/sprite/common.png);
    background-position: -1396px -1187px;
    width: 11px;
    height: 19px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_fill:hover span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .slides .controller button {
    position: absolute;
    display: none;
    border: none;
    background-color: transparent; }
    .slides .controller button.on {
      display: block; }
  .slides .controller span {
    display: inline-block; }
  .slides .controller .btn_next span {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.pad0 {
  padding: 0 !important; }

.header {
  height: 50px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  z-index: 550; }
  .header .row {
    height: 100%;
    padding: 0 20px; }
  .header h1 {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    line-height: 45px; }
    .header h1 span {
      padding: 4px 0;
      display: inline-block;
      position: relative; }
  .header a {
    display: inline-block;
    text-decoration: none;
    color: #13141a; }
  .header .main_nav {
    float: left; }
  .header .sub_nav {
    float: right; }
    .header .sub_nav button {
      height: 100%;
      padding: 0 14px 0 0;
      position: relative;
      background: transparent;
      border: none;
      font-size: 14px; }
      .header .sub_nav button:after {
        content: '';
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: url(https://cf.realclass.co.kr/qualson/images/bt_dropdown_m.png) no-repeat right center;
        background-size: 10px auto; }
      .header .sub_nav button.on {
        color: #2bde73; }
        .header .sub_nav button.on:after {
          background-image: url(https://cf.realclass.co.kr/qualson/images/bt_dropdown_m_on.png);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg); }
  .header .main_nav,
  .header .sub_nav {
    font-size: 0; }
    .header .main_nav > *,
    .header .sub_nav > * {
      height: 50px;
      display: inline-block;
      vertical-align: middle; }
    .header .main_nav .sub_nav_login,
    .header .sub_nav .sub_nav_login {
      font-size: 13px;
      line-height: 50px; }
  .header .bt_toggle {
    width: 16px;
    height: 50px;
    background: transparent url(https://cf.realclass.co.kr/qualson/images/bt_menu_m.png?20181105) no-repeat center;
    background-size: 100% auto;
    border: none; }
    .header .bt_toggle.on {
      background-image: url(https://cf.realclass.co.kr/qualson/images/bt_menu_m_on.png?20181105); }
  .header .logo {
    margin-left: 18px; }
    .header .logo a {
      width: 95px;
      height: 50px;
      display: block;
      background: url(https://cf.realclass.co.kr/qualson/images/realclass_logo_m.png?20181105) no-repeat center;
      background-size: 100% auto; }
  .header .btn_group button {
    border: none;
    background-color: transparent;
    cursor: pointer; }
    .header .btn_group button:active {
      background-color: rgba(255, 255, 255, 0.7); }
  .header .btn_group a {
    text-decoration: none;
    display: block;
    line-height: 45px; }
  .header .sub_lnb {
    display: none;
    position: absolute;
    bottom: 0; }
  .header .bt_package {
    margin-left: 24px;
    padding-top: 15px;
    font-size: 13px; }
    .header .bt_package.on {
      color: #2bde73; }
      .header .bt_package.on span:before, .header .bt_package.on span:after {
        background-color: #2bde73; }
    .header .bt_package span {
      padding: 3px 0 1px;
      position: relative;
      display: inline-block; }
      .header .bt_package span:before, .header .bt_package span:after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        background-color: #13141a; }
      .header .bt_package span:before {
        top: 0; }
      .header .bt_package span:after {
        bottom: 0; }
  .header .btn_close,
  .header .btn_menu,
  .header .btn_back {
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    background-color: transparent;
    border: none; }
    .header .btn_close:after,
    .header .btn_menu:after,
    .header .btn_back:after {
      content: '';
      display: inline-block;
      vertical-align: middle; }
    .header .btn_close:active,
    .header .btn_menu:active,
    .header .btn_back:active {
      background-color: rgba(255, 255, 255, 0.7); }
  .header .btn_sub_menu {
    width: 50px;
    height: 100%;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 13px;
    color: #fff; }
    .header .btn_sub_menu:active {
      background-color: rgba(255, 255, 255, 0.7); }
    .header .btn_sub_menu.disabled {
      color: rgba(255, 255, 255, 0.2); }
  .header .btn_close:after {
    background-image: url(../images/sprite/common.png);
    background-position: -1396px -504px;
    width: 17px;
    height: 17px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .header .btn_close:after {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .header .btn_close.btn_dark:after {
    background-image: url(../images/sprite/common.png);
    background-position: -1396px -536px;
    width: 17px;
    height: 17px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .header .btn_close.btn_dark:after {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .header .btn_close:active {
    background-color: rgba(255, 255, 255, 0.7); }
  .header .btn_menu:after {
    background: url("https://cf.realclass.co.kr/qualson/images/bt_menu_m.png?20181105") no-repeat center;
    background-size: 16px auto; }
  .header .btn_back:after {
    background-image: url(../images/sprite/common.png);
    background-position: -1396px -1034px;
    width: 11px;
    height: 20px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .header .btn_back:after {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.slide_class_menu {
  height: 100%;
  width: 100%;
  padding: 55px 0 45px;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  z-index: 550;
  -webkit-transition: transform .2s;
  -moz-transition: transform .2s;
  -ms-transition: transform .2s;
  -o-transition: transform .2s;
  transition: transform .2s; }
  .slide_class_menu.show {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  .slide_class_menu .inner_wrap {
    width: 100%;
    height: 100%;
    padding: 0 3px; }
  .slide_class_menu .swiper-wrapper {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    font-size: 0; }
  .slide_class_menu .menu_box {
    width: 50%;
    height: 383px;
    max-height: 100%;
    padding: 14px 15px;
    display: inline-block;
    vertical-align: top;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 5px;
    border: 1px solid #2bde73;
    font-size: 13px;
    color: #13141a;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box; }
  .slide_class_menu .lecture_menu {
    width: 76%;
    min-width: 288px; }
    .slide_class_menu .lecture_menu li {
      padding-left: 43px; }
  .slide_class_menu h3 {
    display: inline-block;
    font-size: 11px;
    color: #626262;
    font-weight: 400;
    border-bottom: 1px solid; }
  .slide_class_menu ul {
    margin-top: 18px; }
  .slide_class_menu li {
    position: relative;
    font-size: 13px; }
    .slide_class_menu li + li {
      margin-top: 17px; }
  .slide_class_menu .tag {
    min-width: 31px;
    padding: 1px 1px 0;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid;
    border-radius: 10px;
    font-size: 10px;
    text-align: center; }
  .slide_class_menu a {
    position: relative;
    display: block;
    color: inherit;
    text-decoration: none; }
    .slide_class_menu a + a {
      margin-top: 16px; }
    .slide_class_menu a.new:before {
      content: '';
      width: 22px;
      height: 15px;
      position: absolute;
      top: -11px;
      left: -11px;
      background: url(https://cf.realclass.co.kr/qualson/images/icon_new_m.png) no-repeat center;
      background-size: 100% auto; }
  .slide_class_menu .swiper-pagination {
    bottom: 16px; }
  .slide_class_menu .swiper-pagination-bullet {
    background-color: #fff;
    border: 1px solid #2bde73;
    opacity: 1; }
  .slide_class_menu .swiper-pagination-bullet-active {
    background-color: #2bde73; }

.slide_main_menu {
  height: 100%;
  width: 100%;
  padding-top: 50px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 550;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: opacity .1s linear, transform .3s linear;
  -moz-transition: opacity .1s linear,transform .3s linear;
  -ms-transition: opacity .1s linear,transform .3s linear;
  -o-transition: opacity .1s linear,transform .3s linear;
  transition: opacity .1s linear,transform .3s linear;
  opacity: 0; }
  .slide_main_menu.show {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  .slide_main_menu .smm_x {
    position: absolute;
    right: 17px;
    top: 68px;
    width: 18px;
    z-index: 1;
    padding: 0;
    border: none;
    background: none; }
    .slide_main_menu .smm_x img {
      width: 100%; }
  .slide_main_menu .scroll_area {
    width: 100%;
    height: 100%;
    padding: 40px 0;
    background-color: rgba(255, 255, 255, 0.95);
    color: #13141a;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center; }
  .slide_main_menu a {
    display: block;
    color: inherit;
    text-decoration: none;
    line-height: 16px; }
    .slide_main_menu a.on {
      font-weight: 600;
      color: #2bde73; }
    .slide_main_menu a span {
      position: relative;
      display: inline-block; }
  .slide_main_menu .gnb {
    font-size: 14px; }
    .slide_main_menu .gnb li + li {
      margin-top: 15px; }
    .slide_main_menu .gnb li.gnbUnderBar:after {
      content: '';
      width: 84px;
      height: 1px;
      background: #a4a8ad;
      display: inline-block;
      margin: 10px 0;
      vertical-align: top; }
  .slide_main_menu .lnb {
    margin-top: 5px;
    font-size: 11px; }
    .slide_main_menu .lnb > li {
      padding: 12px 0; }

.menuClassToggleWrap {
  display: none;
  margin-top: 25px;
  background-color: rgba(247, 249, 250, 0.95);
  padding: 22px 0; }
  .menuClassToggleWrap .menuClassToggleBox {
    display: inline-block; }
    .menuClassToggleWrap .menuClassToggleBox .menu_box {
      text-align: left;
      float: left;
      font-size: 12px; }
      .menuClassToggleWrap .menuClassToggleBox .menu_box + .menu_box {
        margin-left: 20px; }
      .menuClassToggleWrap .menuClassToggleBox .menu_box h3 {
        display: inline-block;
        font-size: 10px;
        font-weight: 500;
        color: #9ea2a6;
        line-height: 14px;
        border-bottom: 1px solid #9ea2a6;
        margin-bottom: 20px; }
      .menuClassToggleWrap .menuClassToggleBox .menu_box.type2 h3 {
        margin-bottom: 12px; }
      .menuClassToggleWrap .menuClassToggleBox .menu_box li + li {
        margin-top: 20px; }
      .menuClassToggleWrap .menuClassToggleBox .menu_box .menuClassBox a {
        padding: 0;
        line-height: 14px; }
        .menuClassToggleWrap .menuClassToggleBox .menu_box .menuClassBox a + a {
          margin-top: 16px; }
        .menuClassToggleWrap .menuClassToggleBox .menu_box .menuClassBox a:first-child {
          margin-top: 8px; }
      .menuClassToggleWrap .menuClassToggleBox .menu_box .tag {
        display: inline-block;
        font-size: 9px;
        color: #13141a;
        width: 26px;
        height: 14px;
        border: 1px solid #13141a;
        text-align: center;
        border-radius: 7px; }

.gnbBox {
  position: relative; }
  .gnbBox > li {
    padding: 17px 0; }
  .gnbBox:after {
    content: '';
    width: 84px;
    height: 1px;
    background: #a4a8ad;
    display: inline-block;
    margin: 10px 0;
    vertical-align: top; }
  .gnbBox .menuClassToggle span {
    padding-right: 14px;
    background: url(https://cf.realclass.co.kr/qualson/images/7662ed25678f9242f33f759f6638279f) right center no-repeat;
    background-size: auto 5px; }
  .gnbBox.mctActive:after {
    display: none; }
  .gnbBox.mctActive .menuClassToggle span {
    color: #2bde73;
    background-image: url(https://cf.realclass.co.kr/qualson/images/667c46b64f322170aa4a1f7739d17be5); }
  .gnbBox.mctActive .menuClassToggleWrap {
    display: block; }
  .gnbBox.mctActive > li.mctliActive {
    padding-bottom: 0; }

.main {
  padding-top: 50px; }

.app_view .main {
  padding-top: 0; }

.popup_main_menu .pop_inner {
  background-color: rgba(0, 0, 0, 0.9);
  height: 100%;
  padding-top: 45px;
  text-align: center;
  width: 100%; }
  .popup_main_menu .pop_inner .pop_head {
    height: 45px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%; }
  .popup_main_menu .pop_inner .pop_body {
    height: 100%;
    overflow-y: scroll;
    padding: 20px 0; }
  .popup_main_menu .pop_inner .pop_title {
    border-bottom: none;
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    font-weight: 400;
    line-height: 45px; }
  .popup_main_menu .pop_inner .btn_close {
    background-color: transparent;
    border: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 40px; }
    .popup_main_menu .pop_inner .btn_close:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      background-image: url(../images/sprite/common.png);
      background-position: -1396px -504px;
      width: 17px;
      height: 17px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .popup_main_menu .pop_inner .btn_close:after {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
    .popup_main_menu .pop_inner .btn_close:active {
      background-color: rgba(255, 255, 255, 0.7); }
  .popup_main_menu .pop_inner a {
    color: #fff;
    text-decoration: none; }
  .popup_main_menu .pop_inner .gnb a {
    display: block;
    font-size: 17px;
    padding: 10px 0; }
    .popup_main_menu .pop_inner .gnb a:active {
      background-color: rgba(255, 255, 255, 0.3); }
  .popup_main_menu .pop_inner .gnb li + li {
    margin-top: 20px; }
  .popup_main_menu .pop_inner .lnb {
    margin-top: 90px; }
    .popup_main_menu .pop_inner .lnb a {
      display: block;
      font-size: 13px;
      padding: 5px 0; }
      .popup_main_menu .pop_inner .lnb a:active {
        background-color: rgba(255, 255, 255, 0.3); }
    .popup_main_menu .pop_inner .lnb li + li {
      margin-top: 25px; }

.float_view .pop_inner {
  background-color: #fff;
  border-radius: 10px;
  max-width: 295px;
  overflow: hidden;
  width: 80%; }

.float_view .pop_body {
  font-size: 14px; }
  .float_view .pop_body .message {
    text-align: center; }

.float_view .pop_foot .btn_group .row {
  background-color: #f6f7f8;
  display: block;
  width: 100%; }
  .float_view .pop_foot .btn_group .row + .row {
    border-top: 1px solid #e6e8ea; }

.float_view .pop_foot .btn_group a {
  display: inline-block;
  line-height: 58px;
  text-decoration: none; }

.float_view .pop_foot .btn_group button,
.float_view .pop_foot .btn_group a {
  background-color: transparent;
  border: none;
  color: #63696b;
  font-size: 15px;
  font-weight: 600;
  height: 59px;
  text-align: center;
  width: 100%; }

.float_view .pop_foot .btn_group .btn_primary {
  color: #00c047; }

.popup_primary .pop_body {
  padding: 37px 20px 28px; }
  .popup_primary .pop_body p {
    line-height: 1.3; }
    .popup_primary .pop_body p strong {
      display: block;
      font-size: 15px;
      font-weight: 400; }
  .popup_primary .pop_body p + p {
    margin-top: 22px; }
  .popup_primary .pop_body .alignCenter {
    text-align: center; }

.popup_primary .pop_foot {
  background-color: #f6f7f8; }
  .popup_primary .pop_foot .col + .col {
    border-left: 1px solid #e6e8ea; }

.popup_primary .btn_group {
  font-size: 0; }
  .popup_primary .btn_group .col {
    display: inline-block;
    width: 49.9%; }
  .popup_primary .btn_group button {
    width: 100%; }

.popup_signup_request .pop_inner {
  background-color: #fff;
  height: 100%;
  overflow-y: scroll;
  width: 100%; }

.popup_signup_request h2 {
  font-size: 20px;
  font-weight: 400;
  text-align: center; }

.popup_signup_request .pop_body {
  padding: 90px 20px 20px;
  text-align: center; }
  .popup_signup_request .pop_body h3 {
    font-size: 11px;
    margin-top: 40px;
    text-align: center; }
    .popup_signup_request .pop_body h3 span {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      display: inline-block;
      padding: 2px; }
  .popup_signup_request .pop_body ul {
    margin: 43px auto 0;
    max-width: 280px; }
  .popup_signup_request .pop_body li {
    min-height: 45px;
    padding: 5px 0 0 70px;
    position: relative;
    text-align: left; }
    .popup_signup_request .pop_body li strong {
      display: block;
      font-size: 15px;
      font-weight: 400; }
    .popup_signup_request .pop_body li span {
      color: #86898c;
      font-size: 11px; }
    .popup_signup_request .pop_body li + li {
      margin-top: 24px; }
    .popup_signup_request .pop_body li:before {
      content: '';
      left: 5px;
      position: absolute;
      top: 0; }
  .popup_signup_request .pop_body .real_free:before {
    background-image: url(../images/sprite/common.png);
    background-position: -675px -1190px;
    width: 48px;
    height: 45px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_signup_request .pop_body .real_free:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .popup_signup_request .pop_body .real_event:before {
    background-image: url(../images/sprite/common.png);
    background-position: -738px -1190px;
    width: 48px;
    height: 45px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_signup_request .pop_body .real_event:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .popup_signup_request .pop_body .real_save:before {
    background-image: url(../images/sprite/common.png);
    background-position: -801px -1190px;
    width: 48px;
    height: 45px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_signup_request .pop_body .real_save:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .popup_signup_request .pop_body .btn_login {
    color: #2bde73;
    display: block;
    font-size: 13px;
    margin: 19px auto 0;
    text-decoration: none; }
    .popup_signup_request .pop_body .btn_login strong {
      border-bottom: 1px solid;
      display: inline-block; }
  .popup_signup_request .pop_body .btn_signup {
    background-color: #2bde73;
    border: none;
    color: #000;
    display: block;
    font-size: 15px;
    height: 46px;
    line-height: 46px;
    margin: 70px auto 0;
    width: 213px; }

.popup_signup_request .pop_foot .btn_close_popup {
  background-color: transparent;
  border: none;
  left: 10px;
  position: absolute;
  top: 10px; }
  .popup_signup_request .pop_foot .btn_close_popup:after {
    content: '';
    display: inline-block;
    background-image: url(../images/sprite/common.png);
    background-position: -1396px -536px;
    width: 17px;
    height: 17px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_signup_request .pop_foot .btn_close_popup:after {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.popup_info_level .pop_inner {
  max-height: 90%;
  overflow-y: scroll; }

.popup_info_level .message {
  font-size: 16px;
  padding: 35px 10px 25px; }
  .popup_info_level .message span + span {
    margin-top: 7px; }
  .popup_info_level .message .en_script {
    font-weight: 600; }
  .popup_info_level .message .ko_script {
    display: block;
    font-size: 15px; }

.popup_info_level .pop_foot .btn_group .row {
  position: relative; }

.popup_info_level .pop_foot .btn_group button {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  padding-left: 75px;
  text-align: left; }

.popup_info_level .info_level button strong {
  height: 100%;
  left: 18px;
  position: absolute;
  top: 0;
  width: 51px; }
  .popup_info_level .info_level button strong:before, .popup_info_level .info_level button strong:after {
    content: '';
    left: 0;
    position: absolute;
    top: 50%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
  .popup_info_level .info_level button strong:before {
    width: 100%;
    background-image: url(../images/sprite/common.png);
    background-position: -1124px -1128px;
    width: 51px;
    height: 15px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_info_level .info_level button strong:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .popup_info_level .info_level button strong:after {
    background-image: url(../images/sprite/common.png);
    background-position: -1058px -1128px;
    width: 51px;
    height: 15px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_info_level .info_level button strong:after {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .popup_info_level .info_level button strong.lv0:after {
    width: 0%; }
  .popup_info_level .info_level button strong.lv1:after {
    width: 33.33%; }
  .popup_info_level .info_level button strong.lv2:after {
    width: 66.66%; }
  .popup_info_level .info_level button strong.lv3:after {
    width: 100%; }

.popup_question h2 {
  color: #2bde73;
  font-size: 19px;
  font-weight: bold;
  margin-top: 39px;
  text-align: center;
  text-decoration: underline;
  text-underline-position: under; }

.popup_question ul {
  margin-left: 20px; }

.popup_question li {
  color: #000;
  font-size: 13px;
  line-height: 1.5;
  list-style-type: disc;
  margin-bottom: 13px;
  text-align: left; }

.popup_question button {
  color: #00c047 !important; }

.popup_absent_remain .pop_head {
  font-size: 15px;
  padding: 18px 0 0;
  text-align: center; }

.popup_absent_remain .pop_body {
  line-height: 1.5; }

.popup_challenge_info.scroll_view .inner {
  padding-bottom: 55px;
  padding-top: 55px; }

.popup_challenge_info .btn_close_popup {
  background-color: transparent;
  border: none;
  height: 50px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 40px; }
  .popup_challenge_info .btn_close_popup:before {
    content: '';
    display: inline-block;
    background-image: url(../images/sprite/common.png);
    background-position: -773px -1346px;
    width: 11px;
    height: 11px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_challenge_info .btn_close_popup:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .popup_challenge_info .btn_close_popup:active {
    background-color: rgba(0, 0, 0, 0.09); }

.popup_challenge_info .pop_inner {
  background-color: #fff;
  max-width: 95%;
  position: relative; }

.popup_challenge_info .pop_head {
  border-bottom: 1px solid #2bde73;
  padding: 15px 0 12px;
  text-align: center; }
  .popup_challenge_info .pop_head h3 {
    font-size: 13px; }

.popup_challenge_info .pop_body {
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 20px 37px; }
  .popup_challenge_info .pop_body h4 {
    border-bottom: 1px solid #828487;
    font-size: 12px;
    margin: 25px 0 6px;
    padding: 0 0 6px; }
  .popup_challenge_info .pop_body ol,
  .popup_challenge_info .pop_body ul {
    margin-top: 8px; }
  .popup_challenge_info .pop_body li {
    padding-left: 9px;
    position: relative; }
    .popup_challenge_info .pop_body li:before {
      content: '-';
      left: 2px;
      position: absolute;
      top: 1px; }
    .popup_challenge_info .pop_body li + li {
      margin-top: 2px; }
  .popup_challenge_info .pop_body em {
    font-style: normal;
    font-weight: 600;
    position: relative; }
    .popup_challenge_info .pop_body em:before {
      background-color: rgba(255, 245, 104, 0.5);
      bottom: 0;
      content: '';
      height: 70%;
      left: 0;
      position: absolute;
      width: 100%; }
    .popup_challenge_info .pop_body em span {
      position: relative; }

.slides .controller.btn_white span {
  background-image: url(../images/sprite/common.png);
  background-position: -1396px 0px;
  width: 22px;
  height: 41px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .slides .controller.btn_white span {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.slides .controller.btn_gray span {
  background-image: url(../images/sprite/common.png);
  background-position: -1396px -56px;
  width: 22px;
  height: 41px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .slides .controller.btn_gray span {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.slides .controller.btn_small span {
  background-image: url(../images/sprite/common.png);
  background-position: -1433px -878px;
  width: 17px;
  height: 28px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .slides .controller.btn_small span {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.footer {
  background-color: #eceff0;
  color: #878c8d;
  font-size: 11px;
  padding: 22px 0 58px;
  text-align: left; }
  .footer .inner {
    padding: 0 20px; }
  .footer h5,
  .footer h6 {
    font-weight: 400; }
  .footer h5 {
    font-size: 0; }
    .footer h5 * {
      font-size: 11px; }
    .footer h5 strong {
      font-weight: 500; }
    .footer h5 a {
      color: inherit;
      text-decoration: none; }
    .footer h5 + h5 {
      margin-top: 4px; }
  .footer h6 {
    font-size: 11px;
    margin-top: 10px; }
    .footer h6 a {
      color: inherit;
      cursor: pointer;
      text-decoration: none; }
  .footer i {
    font-style: normal;
    margin: 0 6px; }
  .footer .txt_legal_line {
    border-top: 1px solid #dde0e1;
    margin-top: 14px;
    padding-top: 9px; }

@media (max-width: 500px) {
  .footer h5 i {
    display: none; }
  .footer h5 span {
    display: block;
    padding: 5px 0; } }

.btn_link,
.btn_bg_white,
.btn_bg_green,
.btn_bg_black {
  border: none;
  font-size: 11px;
  height: 36px;
  line-height: 35px;
  padding: 0 20px;
  text-align: center;
  text-decoration: none; }

.btn_link:before {
  content: '';
  display: inline-block;
  height: 100%;
  margin-right: 24px;
  vertical-align: middle;
  width: 40px; }

.link_white:before,
.link_green:before,
.link_black:before {
  margin-right: 24px; }

.btn_bg_white,
.link_white {
  background-color: #fff;
  color: #2bde73; }
  .btn_bg_white:active,
  .link_white:active {
    background-color: #e6e9ea; }

.btn_bg_green,
.link_green {
  background-color: #2bde73;
  color: #000; }
  .btn_bg_green:active,
  .link_green:active {
    background-color: #25ad5c; }

.btn_bg_black,
.link_black {
  background-color: #000;
  color: #fff; }
  .btn_bg_black:active,
  .link_black:active {
    background-color: #333; }

.link_icon_white {
  color: #fff; }
  .link_icon_white:before {
    background-image: url(../images/sprite/common.png);
    background-position: -716px -498px;
    width: 17px;
    height: 5px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .link_icon_white:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.link_icon_green {
  color: #2bde73; }
  .link_icon_green:before {
    background-image: url(../images/sprite/common.png);
    background-position: -1288px -293px;
    width: 17px;
    height: 5px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .link_icon_green:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.link_icon_black {
  color: #000; }
  .link_icon_black:before {
    background-image: url(../images/sprite/common.png);
    background-position: -1256px -293px;
    width: 17px;
    height: 5px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .link_icon_black:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.link_white:before {
  background-image: url(../images/sprite/common.png);
  background-position: -1288px -293px;
  width: 17px;
  height: 5px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .link_white:before {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.link_green:before {
  background-image: url(../images/sprite/common.png);
  background-position: -1256px -293px;
  width: 17px;
  height: 5px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .link_green:before {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.link_black:before {
  background-image: url(../images/sprite/common.png);
  background-position: -716px -498px;
  width: 17px;
  height: 5px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .link_black:before {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.main .floating_banner .inner {
  padding-left: 83px;
  padding-right: 10px; }

.floating_banner {
  background-color: rgba(240, 240, 240, 0.9);
  bottom: 0;
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.9);
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 600; }
  .floating_banner .inner {
    height: 50px;
    position: relative;
    text-align: left; }
  .floating_banner .img_area,
  .floating_banner .txt_area {
    display: inline-block;
    vertical-align: middle; }
  .floating_banner .img_area {
    bottom: 0;
    height: 100%;
    left: 10px;
    position: absolute;
    width: 73px; }
    .floating_banner .img_area img {
      bottom: 0;
      height: auto;
      left: 0;
      position: absolute;
      width: 100%; }
  .floating_banner .txt_area {
    color: #000;
    font-size: 12px;
    padding-left: 10px;
    padding-top: 10px; }
    .floating_banner .txt_area b {
      border-bottom: 1px solid;
      display: inline-block;
      margin: 0 4px; }
    .floating_banner .txt_area i {
      border-bottom: 1px solid;
      display: inline-block;
      font-style: normal;
      margin: 0 4px; }
  .floating_banner a {
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

@media (max-width: 400px) {
  .floating_banner .txt_area {
    font-size: 11px;
    padding-left: 0; } }

.legal_line {
  bottom: 5px;
  color: #000;
  font-size: 10px;
  left: 50%;
  position: absolute;
  text-align: center;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

.popup_refund_info,
.popup_apply_success,
.popup_mission,
.popup_marketing,
.popup_mission2,
.popup_mission3,
.popup_mission_final {
  display: none; }
  .popup_refund_info.show,
  .popup_apply_success.show,
  .popup_mission.show,
  .popup_marketing.show,
  .popup_mission2.show,
  .popup_mission3.show,
  .popup_mission_final.show {
    display: block; }
  .popup_refund_info .pop_inner,
  .popup_apply_success .pop_inner,
  .popup_mission .pop_inner,
  .popup_marketing .pop_inner,
  .popup_mission2 .pop_inner,
  .popup_mission3 .pop_inner,
  .popup_mission_final .pop_inner {
    padding-top: 36px;
    text-align: center; }
  .popup_refund_info .pop_head h2,
  .popup_apply_success .pop_head h2,
  .popup_mission .pop_head h2,
  .popup_marketing .pop_head h2,
  .popup_mission2 .pop_head h2,
  .popup_mission3 .pop_head h2,
  .popup_mission_final .pop_head h2 {
    color: #000;
    font-size: 19.3px;
    font-weight: bold; }
  .popup_refund_info .pop_head h3,
  .popup_apply_success .pop_head h3,
  .popup_mission .pop_head h3,
  .popup_marketing .pop_head h3,
  .popup_mission2 .pop_head h3,
  .popup_mission3 .pop_head h3,
  .popup_mission_final .pop_head h3 {
    font-size: 20px;
    font-weight: bold; }
    .popup_refund_info .pop_head h3.title-2,
    .popup_apply_success .pop_head h3.title-2,
    .popup_mission .pop_head h3.title-2,
    .popup_marketing .pop_head h3.title-2,
    .popup_mission2 .pop_head h3.title-2,
    .popup_mission3 .pop_head h3.title-2,
    .popup_mission_final .pop_head h3.title-2 {
      color: #5cd99d; }
    .popup_refund_info .pop_head h3.title-3,
    .popup_apply_success .pop_head h3.title-3,
    .popup_mission .pop_head h3.title-3,
    .popup_marketing .pop_head h3.title-3,
    .popup_mission2 .pop_head h3.title-3,
    .popup_mission3 .pop_head h3.title-3,
    .popup_mission_final .pop_head h3.title-3 {
      color: #4acab9; }
  .popup_refund_info .pop_head h3 + p,
  .popup_apply_success .pop_head h3 + p,
  .popup_mission .pop_head h3 + p,
  .popup_marketing .pop_head h3 + p,
  .popup_mission2 .pop_head h3 + p,
  .popup_mission3 .pop_head h3 + p,
  .popup_mission_final .pop_head h3 + p {
    font-size: 12.3px;
    font-weight: bold;
    margin: 11px auto 15.5px; }
  .popup_refund_info .pop_head h3.title-2 + p,
  .popup_apply_success .pop_head h3.title-2 + p,
  .popup_mission .pop_head h3.title-2 + p,
  .popup_marketing .pop_head h3.title-2 + p,
  .popup_mission2 .pop_head h3.title-2 + p,
  .popup_mission3 .pop_head h3.title-2 + p,
  .popup_mission_final .pop_head h3.title-2 + p {
    color: #5cd99d; }
  .popup_refund_info .pop_head h3.title-3 + p,
  .popup_apply_success .pop_head h3.title-3 + p,
  .popup_mission .pop_head h3.title-3 + p,
  .popup_marketing .pop_head h3.title-3 + p,
  .popup_mission2 .pop_head h3.title-3 + p,
  .popup_mission3 .pop_head h3.title-3 + p,
  .popup_mission_final .pop_head h3.title-3 + p {
    color: #4acab9; }
  .popup_refund_info .pop_head p,
  .popup_apply_success .pop_head p,
  .popup_mission .pop_head p,
  .popup_marketing .pop_head p,
  .popup_mission2 .pop_head p,
  .popup_mission3 .pop_head p,
  .popup_mission_final .pop_head p {
    color: #000000;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.38;
    margin: 21px auto 18.5px;
    width: 206.5px; }
  .popup_refund_info .pop_body,
  .popup_apply_success .pop_body,
  .popup_mission .pop_body,
  .popup_marketing .pop_body,
  .popup_mission2 .pop_body,
  .popup_mission3 .pop_body,
  .popup_mission_final .pop_body {
    padding: 0 19px 21.5px; }
    .popup_refund_info .pop_body.no_padding,
    .popup_apply_success .pop_body.no_padding,
    .popup_mission .pop_body.no_padding,
    .popup_marketing .pop_body.no_padding,
    .popup_mission2 .pop_body.no_padding,
    .popup_mission3 .pop_body.no_padding,
    .popup_mission_final .pop_body.no_padding {
      padding: 0; }
    .popup_refund_info .pop_body p.sub_content,
    .popup_apply_success .pop_body p.sub_content,
    .popup_mission .pop_body p.sub_content,
    .popup_marketing .pop_body p.sub_content,
    .popup_mission2 .pop_body p.sub_content,
    .popup_mission3 .pop_body p.sub_content,
    .popup_mission_final .pop_body p.sub_content {
      background: #f6f7f8;
      color: #9ba3a6;
      font-size: 9.5px;
      font-weight: normal;
      padding: 11px 0 12.5px;
      width: 100%; }
    .popup_refund_info .pop_body p.marketing_text,
    .popup_apply_success .pop_body p.marketing_text,
    .popup_mission .pop_body p.marketing_text,
    .popup_marketing .pop_body p.marketing_text,
    .popup_mission2 .pop_body p.marketing_text,
    .popup_mission3 .pop_body p.marketing_text,
    .popup_mission_final .pop_body p.marketing_text {
      background: #f6f7f8;
      color: #626569;
      font-size: 12.5px;
      font-weight: normal;
      line-height: 1.2;
      padding: 13.5px 8.5px 13px 11.5px;
      text-align: left; }
    .popup_refund_info .pop_body p.guide,
    .popup_apply_success .pop_body p.guide,
    .popup_mission .pop_body p.guide,
    .popup_marketing .pop_body p.guide,
    .popup_mission2 .pop_body p.guide,
    .popup_mission3 .pop_body p.guide,
    .popup_mission_final .pop_body p.guide {
      color: #9ba3a6;
      font-size: 12.5px;
      font-weight: normal;
      margin-top: 19px; }
    .popup_refund_info .pop_body .success-text,
    .popup_apply_success .pop_body .success-text,
    .popup_mission .pop_body .success-text,
    .popup_marketing .pop_body .success-text,
    .popup_mission2 .pop_body .success-text,
    .popup_mission3 .pop_body .success-text,
    .popup_mission_final .pop_body .success-text {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      margin-top: 20.5px; }
    .popup_refund_info .pop_body .careful,
    .popup_apply_success .pop_body .careful,
    .popup_mission .pop_body .careful,
    .popup_marketing .pop_body .careful,
    .popup_mission2 .pop_body .careful,
    .popup_mission3 .pop_body .careful,
    .popup_mission_final .pop_body .careful {
      color: #9ba3a6;
      font-size: 9.5px;
      font-weight: normal;
      line-height: 1.37;
      padding: 14.5px 20px 12.5px 16.5px;
      text-align: left; }
    .popup_refund_info .pop_body ul.study_list,
    .popup_apply_success .pop_body ul.study_list,
    .popup_mission .pop_body ul.study_list,
    .popup_marketing .pop_body ul.study_list,
    .popup_mission2 .pop_body ul.study_list,
    .popup_mission3 .pop_body ul.study_list,
    .popup_mission_final .pop_body ul.study_list {
      border-bottom: 1px solid #f6f7f8;
      padding: 20px; }
      .popup_refund_info .pop_body ul.study_list li,
      .popup_apply_success .pop_body ul.study_list li,
      .popup_mission .pop_body ul.study_list li,
      .popup_marketing .pop_body ul.study_list li,
      .popup_mission2 .pop_body ul.study_list li,
      .popup_mission3 .pop_body ul.study_list li,
      .popup_mission_final .pop_body ul.study_list li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative; }
        .popup_refund_info .pop_body ul.study_list li + li,
        .popup_apply_success .pop_body ul.study_list li + li,
        .popup_mission .pop_body ul.study_list li + li,
        .popup_marketing .pop_body ul.study_list li + li,
        .popup_mission2 .pop_body ul.study_list li + li,
        .popup_mission3 .pop_body ul.study_list li + li,
        .popup_mission_final .pop_body ul.study_list li + li {
          margin-top: 20px; }
        .popup_refund_info .pop_body ul.study_list li.regular,
        .popup_apply_success .pop_body ul.study_list li.regular,
        .popup_mission .pop_body ul.study_list li.regular,
        .popup_marketing .pop_body ul.study_list li.regular,
        .popup_mission2 .pop_body ul.study_list li.regular,
        .popup_mission3 .pop_body ul.study_list li.regular,
        .popup_mission_final .pop_body ul.study_list li.regular {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: left; }
          .popup_refund_info .pop_body ul.study_list li.regular .head,
          .popup_apply_success .pop_body ul.study_list li.regular .head,
          .popup_mission .pop_body ul.study_list li.regular .head,
          .popup_marketing .pop_body ul.study_list li.regular .head,
          .popup_mission2 .pop_body ul.study_list li.regular .head,
          .popup_mission3 .pop_body ul.study_list li.regular .head,
          .popup_mission_final .pop_body ul.study_list li.regular .head {
            display: flex;
            margin-bottom: 13px; }
            .popup_refund_info .pop_body ul.study_list li.regular .head .text,
            .popup_apply_success .pop_body ul.study_list li.regular .head .text,
            .popup_mission .pop_body ul.study_list li.regular .head .text,
            .popup_marketing .pop_body ul.study_list li.regular .head .text,
            .popup_mission2 .pop_body ul.study_list li.regular .head .text,
            .popup_mission3 .pop_body ul.study_list li.regular .head .text,
            .popup_mission_final .pop_body ul.study_list li.regular .head .text {
              flex: 1;
              text-align: left;
              word-break: keep-all; }
          .popup_refund_info .pop_body ul.study_list li.regular .content,
          .popup_apply_success .pop_body ul.study_list li.regular .content,
          .popup_mission .pop_body ul.study_list li.regular .content,
          .popup_marketing .pop_body ul.study_list li.regular .content,
          .popup_mission2 .pop_body ul.study_list li.regular .content,
          .popup_mission3 .pop_body ul.study_list li.regular .content,
          .popup_mission_final .pop_body ul.study_list li.regular .content {
            width: 100%; }
            .popup_refund_info .pop_body ul.study_list li.regular .content .writingContents,
            .popup_apply_success .pop_body ul.study_list li.regular .content .writingContents,
            .popup_mission .pop_body ul.study_list li.regular .content .writingContents,
            .popup_marketing .pop_body ul.study_list li.regular .content .writingContents,
            .popup_mission2 .pop_body ul.study_list li.regular .content .writingContents,
            .popup_mission3 .pop_body ul.study_list li.regular .content .writingContents,
            .popup_mission_final .pop_body ul.study_list li.regular .content .writingContents {
              border: solid 1px #e1e3e6;
              border-radius: 0;
              box-sizing: border-box;
              padding: 5px 10px;
              width: 100%; }
        .popup_refund_info .pop_body ul.study_list li.flexible .head,
        .popup_apply_success .pop_body ul.study_list li.flexible .head,
        .popup_mission .pop_body ul.study_list li.flexible .head,
        .popup_marketing .pop_body ul.study_list li.flexible .head,
        .popup_mission2 .pop_body ul.study_list li.flexible .head,
        .popup_mission3 .pop_body ul.study_list li.flexible .head,
        .popup_mission_final .pop_body ul.study_list li.flexible .head {
          align-items: flex-start;
          display: flex;
          margin-bottom: 13px; }
        .popup_refund_info .pop_body ul.study_list li .count,
        .popup_apply_success .pop_body ul.study_list li .count,
        .popup_mission .pop_body ul.study_list li .count,
        .popup_marketing .pop_body ul.study_list li .count,
        .popup_mission2 .pop_body ul.study_list li .count,
        .popup_mission3 .pop_body ul.study_list li .count,
        .popup_mission_final .pop_body ul.study_list li .count {
          color: #9ba3a6;
          font-size: 8.8px;
          font-weight: normal;
          position: absolute;
          right: 0;
          top: 17px; }
        .popup_refund_info .pop_body ul.study_list li .btn-submit,
        .popup_apply_success .pop_body ul.study_list li .btn-submit,
        .popup_mission .pop_body ul.study_list li .btn-submit,
        .popup_marketing .pop_body ul.study_list li .btn-submit,
        .popup_mission2 .pop_body ul.study_list li .btn-submit,
        .popup_mission3 .pop_body ul.study_list li .btn-submit,
        .popup_mission_final .pop_body ul.study_list li .btn-submit {
          background: #fff;
          border: 1px solid #e1e3e6;
          color: #e1e3e6;
          font-size: 12px;
          font-weight: bold;
          height: 30px;
          margin: 0 auto;
          padding: 0;
          position: relative;
          width: 100px; }
          .popup_refund_info .pop_body ul.study_list li .btn-submit.active,
          .popup_apply_success .pop_body ul.study_list li .btn-submit.active,
          .popup_mission .pop_body ul.study_list li .btn-submit.active,
          .popup_marketing .pop_body ul.study_list li .btn-submit.active,
          .popup_mission2 .pop_body ul.study_list li .btn-submit.active,
          .popup_mission3 .pop_body ul.study_list li .btn-submit.active,
          .popup_mission_final .pop_body ul.study_list li .btn-submit.active {
            border: solid 1px #13141a;
            color: #13141a; }
          .popup_refund_info .pop_body ul.study_list li .btn-submit.completed,
          .popup_apply_success .pop_body ul.study_list li .btn-submit.completed,
          .popup_mission .pop_body ul.study_list li .btn-submit.completed,
          .popup_marketing .pop_body ul.study_list li .btn-submit.completed,
          .popup_mission2 .pop_body ul.study_list li .btn-submit.completed,
          .popup_mission3 .pop_body ul.study_list li .btn-submit.completed,
          .popup_mission_final .pop_body ul.study_list li .btn-submit.completed {
            border: none;
            color: #2bde73; }
          .popup_refund_info .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_apply_success .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_mission .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_marketing .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_mission2 .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_mission3 .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_mission_final .pop_body ul.study_list li .btn-submit.completed:after {
            background: url("https://cf.realclass.co.kr/qualson/images/c4e3b6af199258ce17ca126eec3a353f");
            background-size: 100% 100%;
            content: '';
            display: block;
            height: 6px;
            position: absolute;
            right: 17px;
            top: 10px;
            width: 7.7px; }
        .popup_refund_info .pop_body ul.study_list li .content,
        .popup_apply_success .pop_body ul.study_list li .content,
        .popup_mission .pop_body ul.study_list li .content,
        .popup_marketing .pop_body ul.study_list li .content,
        .popup_mission2 .pop_body ul.study_list li .content,
        .popup_mission3 .pop_body ul.study_list li .content,
        .popup_mission_final .pop_body ul.study_list li .content {
          position: relative;
          text-align: left; }
          .popup_refund_info .pop_body ul.study_list li .content textarea,
          .popup_apply_success .pop_body ul.study_list li .content textarea,
          .popup_mission .pop_body ul.study_list li .content textarea,
          .popup_marketing .pop_body ul.study_list li .content textarea,
          .popup_mission2 .pop_body ul.study_list li .content textarea,
          .popup_mission3 .pop_body ul.study_list li .content textarea,
          .popup_mission_final .pop_body ul.study_list li .content textarea {
            -webkit-appearance: none;
            border: solid 1px #e1e3e6;
            border-radius: 0;
            color: #13141a;
            font-size: 12px;
            font-weight: normal;
            height: 86px;
            padding: 10px;
            resize: none;
            width: 100%; }
          .popup_refund_info .pop_body ul.study_list li .content.m20,
          .popup_apply_success .pop_body ul.study_list li .content.m20,
          .popup_mission .pop_body ul.study_list li .content.m20,
          .popup_marketing .pop_body ul.study_list li .content.m20,
          .popup_mission2 .pop_body ul.study_list li .content.m20,
          .popup_mission3 .pop_body ul.study_list li .content.m20,
          .popup_mission_final .pop_body ul.study_list li .content.m20 {
            margin-left: 20px; }
          .popup_refund_info .pop_body ul.study_list li .content input[type='file'],
          .popup_apply_success .pop_body ul.study_list li .content input[type='file'],
          .popup_mission .pop_body ul.study_list li .content input[type='file'],
          .popup_marketing .pop_body ul.study_list li .content input[type='file'],
          .popup_mission2 .pop_body ul.study_list li .content input[type='file'],
          .popup_mission3 .pop_body ul.study_list li .content input[type='file'],
          .popup_mission_final .pop_body ul.study_list li .content input[type='file'] {
            cursor: pointer;
            height: 156px;
            left: 0px;
            opacity: 0;
            position: absolute;
            top: 0px;
            width: 196.5px; }
          .popup_refund_info .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_apply_success .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_mission .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_marketing .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_mission2 .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_mission3 .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_mission_final .pop_body ul.study_list li .content .wrapCheckLevel {
            padding-left: 28px; }
            .popup_refund_info .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_apply_success .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_mission .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_marketing .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_mission2 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_mission3 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_mission_final .pop_body ul.study_list li .content .wrapCheckLevel .btn-level {
              background: #fff;
              border: 1px solid #e1e3e6;
              border-radius: 10.6px;
              color: #e1e3e6;
              display: inline-block;
              font-size: 12.5px;
              font-weight: 500;
              height: 21.5px;
              line-height: 21.5px;
              text-align: center;
              white-space: nowrap;
              width: 60px; }
              .popup_refund_info .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_apply_success .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_mission .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_marketing .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_mission2 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_mission3 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_mission_final .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on {
                background: #13141a;
                border: none;
                color: #fff; }
              .popup_refund_info .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_apply_success .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_mission .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_marketing .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_mission2 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_mission3 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_mission_final .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2) {
                margin: 0 6px; }
          .popup_refund_info .pop_body ul.study_list li .content .btn-upload,
          .popup_apply_success .pop_body ul.study_list li .content .btn-upload,
          .popup_mission .pop_body ul.study_list li .content .btn-upload,
          .popup_marketing .pop_body ul.study_list li .content .btn-upload,
          .popup_mission2 .pop_body ul.study_list li .content .btn-upload,
          .popup_mission3 .pop_body ul.study_list li .content .btn-upload,
          .popup_mission_final .pop_body ul.study_list li .content .btn-upload {
            background: url("https://cf.realclass.co.kr/qualson/images/a69df8e74bb9fd60673ac78de01d6392");
            background-size: 100% 100%;
            border: none;
            height: 156px;
            position: relative;
            width: 196.5px; }
            .popup_refund_info .pop_body ul.study_list li .content .btn-upload:after,
            .popup_apply_success .pop_body ul.study_list li .content .btn-upload:after,
            .popup_mission .pop_body ul.study_list li .content .btn-upload:after,
            .popup_marketing .pop_body ul.study_list li .content .btn-upload:after,
            .popup_mission2 .pop_body ul.study_list li .content .btn-upload:after,
            .popup_mission3 .pop_body ul.study_list li .content .btn-upload:after,
            .popup_mission_final .pop_body ul.study_list li .content .btn-upload:after {
              background: url("https://cf.realclass.co.kr/qualson/images/7d0dbd3e92efe5b8dcd3ae50f0b0c23a");
              background-size: 100% 100%;
              bottom: 0;
              content: '';
              display: block;
              height: 25px;
              position: absolute;
              right: 0;
              width: 82px; }
            .popup_refund_info .pop_body ul.study_list li .content .btn-upload.off,
            .popup_apply_success .pop_body ul.study_list li .content .btn-upload.off,
            .popup_mission .pop_body ul.study_list li .content .btn-upload.off,
            .popup_marketing .pop_body ul.study_list li .content .btn-upload.off,
            .popup_mission2 .pop_body ul.study_list li .content .btn-upload.off,
            .popup_mission3 .pop_body ul.study_list li .content .btn-upload.off,
            .popup_mission_final .pop_body ul.study_list li .content .btn-upload.off {
              display: none; }
          .popup_refund_info .pop_body ul.study_list li .content div.pic,
          .popup_apply_success .pop_body ul.study_list li .content div.pic,
          .popup_mission .pop_body ul.study_list li .content div.pic,
          .popup_marketing .pop_body ul.study_list li .content div.pic,
          .popup_mission2 .pop_body ul.study_list li .content div.pic,
          .popup_mission3 .pop_body ul.study_list li .content div.pic,
          .popup_mission_final .pop_body ul.study_list li .content div.pic {
            background: url("https://cf.realclass.co.kr/qualson/images/6b4ef9547d72a61d95b39c1c191be7b2");
            background-size: 100% 100%;
            display: none;
            height: 101.3px;
            position: relative;
            width: 100.7px; }
            .popup_refund_info .pop_body ul.study_list li .content div.pic.on,
            .popup_apply_success .pop_body ul.study_list li .content div.pic.on,
            .popup_mission .pop_body ul.study_list li .content div.pic.on,
            .popup_marketing .pop_body ul.study_list li .content div.pic.on,
            .popup_mission2 .pop_body ul.study_list li .content div.pic.on,
            .popup_mission3 .pop_body ul.study_list li .content div.pic.on,
            .popup_mission_final .pop_body ul.study_list li .content div.pic.on {
              display: block; }
            .popup_refund_info .pop_body ul.study_list li .content div.pic.on:after,
            .popup_apply_success .pop_body ul.study_list li .content div.pic.on:after,
            .popup_mission .pop_body ul.study_list li .content div.pic.on:after,
            .popup_marketing .pop_body ul.study_list li .content div.pic.on:after,
            .popup_mission2 .pop_body ul.study_list li .content div.pic.on:after,
            .popup_mission3 .pop_body ul.study_list li .content div.pic.on:after,
            .popup_mission_final .pop_body ul.study_list li .content div.pic.on:after {
              background: url("https://cf.realclass.co.kr/qualson/images/7a5f04f260d909e238588e064dd1c6b4");
              background-size: 100% 100%;
              bottom: -10px;
              content: '';
              display: block;
              height: 22.7px;
              position: absolute;
              right: -10px;
              width: 23px; }
        .popup_refund_info .pop_body ul.study_list li .wrapCheckStar,
        .popup_apply_success .pop_body ul.study_list li .wrapCheckStar,
        .popup_mission .pop_body ul.study_list li .wrapCheckStar,
        .popup_marketing .pop_body ul.study_list li .wrapCheckStar,
        .popup_mission2 .pop_body ul.study_list li .wrapCheckStar,
        .popup_mission3 .pop_body ul.study_list li .wrapCheckStar,
        .popup_mission_final .pop_body ul.study_list li .wrapCheckStar {
          padding-left: 28px; }
          .popup_refund_info .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_apply_success .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_mission .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_marketing .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_mission2 .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_mission3 .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_mission_final .pop_body ul.study_list li .wrapCheckStar .star {
            background: url("https://cf.realclass.co.kr/qualson/images/dbb782f00dd48b544073e1cb8cea0e3e");
            background-size: 100% 100%;
            display: inline-block;
            height: 24px;
            width: 25.7px; }
            .popup_refund_info .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_apply_success .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_mission .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_marketing .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_mission2 .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_mission3 .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_mission_final .pop_body ul.study_list li .wrapCheckStar .star.on {
              background: url("https://cf.realclass.co.kr/qualson/images/1c4861138571e98c78c437d05c13b372");
              background-size: 100% 100%; }
        .popup_refund_info .pop_body ul.study_list li .icoIndex,
        .popup_apply_success .pop_body ul.study_list li .icoIndex,
        .popup_mission .pop_body ul.study_list li .icoIndex,
        .popup_marketing .pop_body ul.study_list li .icoIndex,
        .popup_mission2 .pop_body ul.study_list li .icoIndex,
        .popup_mission3 .pop_body ul.study_list li .icoIndex,
        .popup_mission_final .pop_body ul.study_list li .icoIndex {
          background-color: #5cd99d;
          border-radius: 10px;
          color: #fff;
          font-style: normal;
          height: 20px;
          line-height: 20px;
          margin-right: 10px;
          width: 20px; }
        .popup_refund_info .pop_body ul.study_list li .ico-check,
        .popup_apply_success .pop_body ul.study_list li .ico-check,
        .popup_mission .pop_body ul.study_list li .ico-check,
        .popup_marketing .pop_body ul.study_list li .ico-check,
        .popup_mission2 .pop_body ul.study_list li .ico-check,
        .popup_mission3 .pop_body ul.study_list li .ico-check,
        .popup_mission_final .pop_body ul.study_list li .ico-check {
          background: url("https://cf.realclass.co.kr/qualson/images/b94bb8e344bdd8704eeff22f0c9c9871");
          background-size: 100% 100%;
          display: inline-block;
          height: 18px;
          margin-right: 10px;
          width: 18px; }
          .popup_refund_info .pop_body ul.study_list li .ico-check.on,
          .popup_apply_success .pop_body ul.study_list li .ico-check.on,
          .popup_mission .pop_body ul.study_list li .ico-check.on,
          .popup_marketing .pop_body ul.study_list li .ico-check.on,
          .popup_mission2 .pop_body ul.study_list li .ico-check.on,
          .popup_mission3 .pop_body ul.study_list li .ico-check.on,
          .popup_mission_final .pop_body ul.study_list li .ico-check.on {
            background: url("https://cf.realclass.co.kr/qualson/images/128ba5d03efb592f6b5f1af0e330c0bf");
            background-size: 100% 100%; }
        .popup_refund_info .pop_body ul.study_list li .btn-group,
        .popup_apply_success .pop_body ul.study_list li .btn-group,
        .popup_mission .pop_body ul.study_list li .btn-group,
        .popup_marketing .pop_body ul.study_list li .btn-group,
        .popup_mission2 .pop_body ul.study_list li .btn-group,
        .popup_mission3 .pop_body ul.study_list li .btn-group,
        .popup_mission_final .pop_body ul.study_list li .btn-group {
          align-items: center;
          display: flex;
          text-align: left; }
          .popup_refund_info .pop_body ul.study_list li .btn-group span.btn,
          .popup_apply_success .pop_body ul.study_list li .btn-group span.btn,
          .popup_mission .pop_body ul.study_list li .btn-group span.btn,
          .popup_marketing .pop_body ul.study_list li .btn-group span.btn,
          .popup_mission2 .pop_body ul.study_list li .btn-group span.btn,
          .popup_mission3 .pop_body ul.study_list li .btn-group span.btn,
          .popup_mission_final .pop_body ul.study_list li .btn-group span.btn {
            display: inline-block;
            height: 23px;
            margin-right: 7.5px;
            width: 46px; }
          .popup_refund_info .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_apply_success .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_mission .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_marketing .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_mission2 .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_mission3 .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_mission_final .pop_body ul.study_list li .btn-group .btn-voice {
            background: url("https://cf.realclass.co.kr/qualson/images/259653dcd66a5e0494da24176eb1a613");
            background-size: 100% 100%; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_apply_success .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_mission .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_marketing .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_mission2 .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_mission3 .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_mission_final .pop_body ul.study_list li .btn-group .btn-voice.on {
              background: url("https://cf.realclass.co.kr/qualson/images/e0d26776b7640d0ceb5baf035551120e");
              background-size: 100% 100%; }
          .popup_refund_info .pop_body ul.study_list li .btn-group .btn-play,
          .popup_apply_success .pop_body ul.study_list li .btn-group .btn-play,
          .popup_mission .pop_body ul.study_list li .btn-group .btn-play,
          .popup_marketing .pop_body ul.study_list li .btn-group .btn-play,
          .popup_mission2 .pop_body ul.study_list li .btn-group .btn-play,
          .popup_mission3 .pop_body ul.study_list li .btn-group .btn-play,
          .popup_mission_final .pop_body ul.study_list li .btn-group .btn-play {
            background: url("https://cf.realclass.co.kr/qualson/images/2b95fd452f66ef2d9bffd9a1026fa31e");
            background-size: 100% 100%; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_apply_success .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_mission .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_marketing .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_mission2 .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_mission3 .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_mission_final .pop_body ul.study_list li .btn-group .btn-play.on {
              background: url("https://cf.realclass.co.kr/qualson/images/92ec9555e7a076c1892f66007f82d790");
              background-size: 100% 100%; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_apply_success .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_mission .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_marketing .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_mission2 .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_mission3 .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_mission_final .pop_body ul.study_list li .btn-group .btn-play.stop {
              background: url("https://cf.realclass.co.kr/qualson/images/b390a00d137f68c426f1233d9032bc92");
              background-size: 100% 100%; }
          .popup_refund_info .pop_body ul.study_list li .btn-group .time,
          .popup_apply_success .pop_body ul.study_list li .btn-group .time,
          .popup_mission .pop_body ul.study_list li .btn-group .time,
          .popup_marketing .pop_body ul.study_list li .btn-group .time,
          .popup_mission2 .pop_body ul.study_list li .btn-group .time,
          .popup_mission3 .pop_body ul.study_list li .btn-group .time,
          .popup_mission_final .pop_body ul.study_list li .btn-group .time {
            bottom: 3px;
            color: #2bde73;
            font-size: 12.5px;
            font-weight: normal;
            left: 51px;
            position: absolute; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .time.record,
            .popup_apply_success .pop_body ul.study_list li .btn-group .time.record,
            .popup_mission .pop_body ul.study_list li .btn-group .time.record,
            .popup_marketing .pop_body ul.study_list li .btn-group .time.record,
            .popup_mission2 .pop_body ul.study_list li .btn-group .time.record,
            .popup_mission3 .pop_body ul.study_list li .btn-group .time.record,
            .popup_mission_final .pop_body ul.study_list li .btn-group .time.record {
              color: #ff414d; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .time.play,
            .popup_apply_success .pop_body ul.study_list li .btn-group .time.play,
            .popup_mission .pop_body ul.study_list li .btn-group .time.play,
            .popup_marketing .pop_body ul.study_list li .btn-group .time.play,
            .popup_mission2 .pop_body ul.study_list li .btn-group .time.play,
            .popup_mission3 .pop_body ul.study_list li .btn-group .time.play,
            .popup_mission_final .pop_body ul.study_list li .btn-group .time.play {
              color: #2bde73; }
          .popup_refund_info .pop_body ul.study_list li .btn-group .ico,
          .popup_apply_success .pop_body ul.study_list li .btn-group .ico,
          .popup_mission .pop_body ul.study_list li .btn-group .ico,
          .popup_marketing .pop_body ul.study_list li .btn-group .ico,
          .popup_mission2 .pop_body ul.study_list li .btn-group .ico,
          .popup_mission3 .pop_body ul.study_list li .btn-group .ico,
          .popup_mission_final .pop_body ul.study_list li .btn-group .ico {
            display: inline-block;
            height: 13px;
            margin-left: 9px;
            width: 15px; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .ico.play,
            .popup_apply_success .pop_body ul.study_list li .btn-group .ico.play,
            .popup_mission .pop_body ul.study_list li .btn-group .ico.play,
            .popup_marketing .pop_body ul.study_list li .btn-group .ico.play,
            .popup_mission2 .pop_body ul.study_list li .btn-group .ico.play,
            .popup_mission3 .pop_body ul.study_list li .btn-group .ico.play,
            .popup_mission_final .pop_body ul.study_list li .btn-group .ico.play {
              background: url("https://cf.realclass.co.kr/qualson/images/bd33639e767b311a0d4bfe2a657379b0");
              background-size: 100% 100%; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .ico.record,
            .popup_apply_success .pop_body ul.study_list li .btn-group .ico.record,
            .popup_mission .pop_body ul.study_list li .btn-group .ico.record,
            .popup_marketing .pop_body ul.study_list li .btn-group .ico.record,
            .popup_mission2 .pop_body ul.study_list li .btn-group .ico.record,
            .popup_mission3 .pop_body ul.study_list li .btn-group .ico.record,
            .popup_mission_final .pop_body ul.study_list li .btn-group .ico.record {
              background: url("https://cf.realclass.co.kr/qualson/images/72ce4715dbafd36494b5187ed64a1885");
              background-size: 100% 100%; }
    .popup_refund_info .pop_body ul.info_list li,
    .popup_apply_success .pop_body ul.info_list li,
    .popup_mission .pop_body ul.info_list li,
    .popup_marketing .pop_body ul.info_list li,
    .popup_mission2 .pop_body ul.info_list li,
    .popup_mission3 .pop_body ul.info_list li,
    .popup_mission_final .pop_body ul.info_list li {
      background: #f6f7f8;
      display: flex;
      padding: 10px 20px 7px;
      width: 256.5px; }
      .popup_refund_info .pop_body ul.info_list li + li,
      .popup_apply_success .pop_body ul.info_list li + li,
      .popup_mission .pop_body ul.info_list li + li,
      .popup_marketing .pop_body ul.info_list li + li,
      .popup_mission2 .pop_body ul.info_list li + li,
      .popup_mission3 .pop_body ul.info_list li + li,
      .popup_mission_final .pop_body ul.info_list li + li {
        margin-top: 10px; }
      .popup_refund_info .pop_body ul.info_list li p,
      .popup_apply_success .pop_body ul.info_list li p,
      .popup_mission .pop_body ul.info_list li p,
      .popup_marketing .pop_body ul.info_list li p,
      .popup_mission2 .pop_body ul.info_list li p,
      .popup_mission3 .pop_body ul.info_list li p,
      .popup_mission_final .pop_body ul.info_list li p {
        margin-left: 8.5px;
        text-align: left; }
    .popup_refund_info .pop_body ul.mission_list,
    .popup_apply_success .pop_body ul.mission_list,
    .popup_mission .pop_body ul.mission_list,
    .popup_marketing .pop_body ul.mission_list,
    .popup_mission2 .pop_body ul.mission_list,
    .popup_mission3 .pop_body ul.mission_list,
    .popup_mission_final .pop_body ul.mission_list {
      margin-top: 22.5px;
      padding-left: 25px; }
      .popup_refund_info .pop_body ul.mission_list li,
      .popup_apply_success .pop_body ul.mission_list li,
      .popup_mission .pop_body ul.mission_list li,
      .popup_marketing .pop_body ul.mission_list li,
      .popup_mission2 .pop_body ul.mission_list li,
      .popup_mission3 .pop_body ul.mission_list li,
      .popup_mission_final .pop_body ul.mission_list li {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        list-style-type: disc;
        text-align: left; }
        .popup_refund_info .pop_body ul.mission_list li + li,
        .popup_apply_success .pop_body ul.mission_list li + li,
        .popup_mission .pop_body ul.mission_list li + li,
        .popup_marketing .pop_body ul.mission_list li + li,
        .popup_mission2 .pop_body ul.mission_list li + li,
        .popup_mission3 .pop_body ul.mission_list li + li,
        .popup_mission_final .pop_body ul.mission_list li + li {
          margin-top: 16px; }
  .popup_refund_info .btn_group button,
  .popup_apply_success .btn_group button,
  .popup_mission .btn_group button,
  .popup_marketing .btn_group button,
  .popup_mission2 .btn_group button,
  .popup_mission3 .btn_group button,
  .popup_mission_final .btn_group button {
    color: #2bde73 !important; }

.pic-swiper {
  padding: 0 21px; }

.pic-swiper-container.swiper-container {
  height: 100%;
  width: 100%; }

.pic-swiper-container .swiper-wrapper {
  display: flex; }
  .pic-swiper-container .swiper-wrapper .swiper-slide + .swiper-slide {
    margin-left: 18px; }
  .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item {
    height: 156px;
    position: relative;
    width: 196.5px; }
    .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item.rect {
      height: 180px;
      width: 180px; }
    .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content {
      position: relative;
      text-align: left; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content textarea {
        -webkit-appearance: none;
        border: solid 1px #e1e3e6;
        border-radius: 0;
        color: #13141a;
        font-size: 12.5px;
        font-weight: normal;
        height: 86.5px;
        margin-top: 3px;
        padding: 12px 4.5px 8px 13px;
        resize: none;
        width: 254.5px; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content.m20 {
        margin-left: 20px; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content input[type='file'] {
        cursor: pointer;
        height: 156px;
        left: 0px;
        opacity: 0;
        position: absolute;
        top: 0px;
        width: 196.5px; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-level {
        background: #fff;
        border: 1px solid #e1e3e6;
        border-radius: 10.6px;
        color: #e1e3e6;
        display: inline-block;
        font-size: 12.5px;
        font-weight: 500;
        height: 21.5px;
        line-height: 21.5px;
        text-align: center;
        white-space: nowrap;
        width: 60px; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-level.on {
          background: #13141a;
          border: none;
          color: #fff; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-level:nth-child(2) {
          margin: 0 6px; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-upload {
        background: url("https://cf.realclass.co.kr/qualson/images/a69df8e74bb9fd60673ac78de01d6392");
        background-size: 100% 100%;
        border: none;
        height: 156px;
        width: 196.5px; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-upload.off {
          display: none; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-upload:after {
          background: url("https://cf.realclass.co.kr/qualson/images/7d0dbd3e92efe5b8dcd3ae50f0b0c23a");
          background-size: 100% 100%;
          bottom: 50%;
          content: '';
          display: block;
          height: 25px;
          margin-bottom: -12.5px;
          position: absolute;
          right: -41px;
          width: 82px;
          z-index: 100; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content div.pic {
        background-size: 100% 100%;
        display: none;
        height: 156px;
        position: relative;
        width: 196.5px; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content div.pic.on {
          display: block; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content div.pic.on:after {
          background: url("https://cf.realclass.co.kr/qualson/images/7a5f04f260d909e238588e064dd1c6b4");
          background-size: 100% 100%;
          bottom: -10px;
          content: '';
          display: block;
          height: 22.7px;
          position: absolute;
          right: -10px;
          width: 23px; }

.leave .check_list {
  margin-top: 46px;
  text-align: left; }
  .leave .check_list .row {
    padding-left: 32px;
    display: block;
    position: relative;
    line-height: 1.4; }
    .leave .check_list .row + .row {
      margin-top: 26px; }
  .leave .check_list textarea {
    width: 100%;
    height: 94px;
    padding: 7px;
    background-color: #f5f5f5;
    border: 3px solid #e5e5e5;
    border-radius: 3px;
    resize: none;
    font-size: 16px; }
  .leave .check_list .fake_radio {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #d0d3d4;
    background-color: #f4f4f4;
    border-radius: 50%; }
  .leave .check_list input[type="radio"]:checked + .fake_radio:after {
    content: '';
    width: 12px;
    height: 12px;
    margin: -6px 0 0 -6px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #000;
    border-radius: 50%; }

.leave h3 {
  margin-top: 120px;
  font-size: 24px;
  color: #363636;
  line-height: 1.6; }
  .leave h3 strong {
    font-size: 15px;
    font-weight: 400; }

.leave_page .header {
  height: 45px;
  background-color: #000;
  border-bottom: none; }

.header .btn_send {
  width: 50px;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #2bde73; }
  .header .btn_send.disabled {
    color: rgba(255, 255, 255, 0.2); }
    .header .btn_send.disabled:active {
      background-color: transparent; }

.page_sub_header {
  position: relative;
  background-color: #fafafa; }
  .page_sub_header h2 {
    padding: 0 20px;
    height: 46px;
    font-size: 13px;
    color: #626569;
    line-height: 46px; }
  .page_sub_header .sub_button {
    width: 62px;
    height: 25px;
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 11px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid;
    line-height: 23px;
    text-align: center;
    color: #000;
    text-decoration: none; }
  .page_sub_header .row {
    height: 46px; }

.classic_login_info {
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  margin: 20px 0 0;
  padding: 0 24px;
  color: #434449;
  font-size: 11px; }
  .classic_login_info img {
    width: 42px;
    margin-right: 10px; }

.account_form {
  padding: 28px 20px; }
  .account_form .progress span {
    height: 2px;
    position: fixed;
    top: 45px;
    left: 0;
    display: none;
    background-color: #3ad478; }
    .account_form .progress span.on {
      display: block; }
  .account_form .progress .step1 {
    width: 50%; }
  .account_form .progress .step2 {
    width: 100%; }
  .account_form .sub_title {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    font-size: 12px;
    font-weight: 400;
    color: #bcc2c9; }
    .account_form .sub_title .warning_message {
      padding-left: 12px; }
  .account_form .mobile_check_row {
    padding-right: 83px; }
    .account_form .mobile_check_row .btn_check {
      height: 34px;
      position: absolute;
      right: 0;
      bottom: 0; }
  .account_form .input_area label {
    padding-top: 22px;
    position: relative;
    display: block; }
    .account_form .input_area label + label {
      margin-top: 40px; }
  .account_form .input_area input {
    width: 100%;
    padding: 6px 2px;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #bcc2c9;
    border-radius: 0; }
  .account_form .input_area .on + .sub_title,
  .account_form .input_area .active + .sub_title,
  .account_form .input_area .warning + .sub_title {
    display: block; }
  .account_form .input_area .warning + .sub_title .warning_message {
    display: inline-block; }
  .account_form .input_area .info_message {
    display: none;
    font-size: 12px;
    font-weight: 400;
    color: #2bde73;
    position: absolute;
    left: 0;
    bottom: -3px;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%); }
    .account_form .input_area .info_message.on {
      display: block; }
  .account_form .input_area .warning {
    border-bottom: 1px solid #ff414d; }
  .account_form .input_area .active {
    border-bottom: 1px solid #2bde73; }
    .account_form .input_area .active + .sub_title {
      color: #2bde73; }
  .account_form .warning_message {
    display: none;
    color: #ff414d; }
  .account_form .txt_description {
    margin-top: 37px;
    font-size: 13px;
    text-align: center; }
    .account_form .txt_description a {
      color: #000;
      text-decoration: underline; }
    .account_form .txt_description strong {
      color: #2bde73;
      font-weight: 400; }
  .account_form .btn_send {
    width: 100%;
    max-width: 217px;
    height: 45px;
    display: block;
    margin: 40px auto 0;
    padding: 0;
    font-size: 14px;
    color: #2bde73;
    border: 1px solid #13141a;
    background-color: #13141a; }
    .account_form .btn_send.disabled {
      color: #bcc2c9;
      border: 1px solid #bcc2c9;
      background-color: transparent; }

.login_form .rel_account {
  margin-top: 46px; }
  .login_form .rel_account p {
    text-align: right; }
  .login_form .rel_account p + p {
    margin-top: 30px; }
  .login_form .rel_account a {
    padding: 1px 0;
    display: inline-block;
    color: #000;
    text-decoration: none;
    border-bottom: 1px solid; }
    .login_form .rel_account a:after {
      content: '';
      margin-left: 5px;
      display: inline-block;
      vertical-align: middle;
      background-image: url(../images/sprite/common.png);
      background-position: -820px -1346px;
      width: 6px;
      height: 10px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .login_form .rel_account a:after {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }

.find_form h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4; }
  .find_form h3 span {
    display: block;
    font-size: 14px;
    color: #bcc2c9; }
    .find_form h3 span:after {
      content: '';
      width: 22px;
      height: 1px;
      display: block;
      margin: 12px auto 9px;
      background-color: #bcc2c9; }

.find_form .result_id {
  padding-top: 22px;
  text-align: center; }
  .find_form .result_id .btn_group {
    margin-top: 42px; }

.find_form .result_id a,
.find_form .result_password a {
  width: 215px;
  height: 45px;
  margin: 0 auto;
  display: block;
  color: #2bde73;
  font-size: 15px;
  line-height: 43px; }

.find_form .result_password {
  padding-top: 58px;
  text-align: center; }
  .find_form .result_password .btn_group {
    margin-top: 45px; }

.leave h2 {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  line-height: 1.4; }

.leave .btn_group {
  text-align: center;
  margin-top: 30px; }
  .leave .btn_group button {
    text-align: center; }

.leave .check_list .row {
  font-size: 16px; }

.leave h3 {
  font-weight: 400;
  text-align: center; }

.btn_check {
  color: #fff;
  background-color: #000;
  font-size: 11px;
  border: none; }
  .btn_check.disabled {
    background-color: #bcc2c9; }

.btn_normal {
  width: 215px;
  height: 46px;
  display: inline-block;
  font-size: 15px;
  color: #2bde73;
  text-decoration: none;
  border: 1px solid #2bde73;
  line-height: 46px; }

.popup_alert .pop_body {
  padding: 29px 5px 30px; }

.popup_alert .message {
  line-height: 1.3; }
  .popup_alert .message strong {
    display: block;
    margin-bottom: 14px;
    font-size: 18px; }
  .popup_alert .message i {
    margin-top: 3px;
    font-style: normal;
    font-size: 14px;
    display: block; }

.popup_alert .pop_foot .btn_group .col {
  display: block;
  background-color: #f6f7f8; }
  .popup_alert .pop_foot .btn_group .col + .col {
    border-top: 1px solid #e6e8ea; }

.popup_alert .pop_foot .btn_group button:active, .popup_alert .pop_foot .btn_group a:active {
  background-color: #dadbdc; }

.signup_request_page {
  background-color: #fff; }
  .signup_request_page .header {
    position: absolute;
    background-color: transparent;
    box-shadow: none; }
    .signup_request_page .header button {
      height: 50px; }
  .signup_request_page .main {
    padding-top: 0; }
  .signup_request_page .main, .signup_request_page .main > div {
    height: 100%; }
  .signup_request_page h2 {
    font-size: 18px;
    text-align: center; }
  .signup_request_page .page_footer {
    padding: 72px 0 37px;
    text-align: center; }
    .signup_request_page .page_footer button {
      width: 213px;
      height: 46px;
      background-color: #2bde73;
      color: #000;
      border: none;
      font-size: 15px; }
    .signup_request_page .page_footer a {
      display: block;
      margin: 19px auto 0;
      font-size: 13px;
      color: #2bde73;
      text-decoration: none; }
      .signup_request_page .page_footer a strong {
        display: inline-block;
        border-bottom: 1px solid; }

.content_group h2, .content_group h3 {
  line-height: 1.3; }

.content_group h3 {
  margin-top: 24px;
  font-size: 12px;
  text-align: center;
  font-weight: 400; }
  .content_group h3 span {
    padding: 2px;
    display: inline-block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3); }

.content_group ul {
  margin: 18px auto 0;
  text-align: center; }

.content_group li {
  width: 90px;
  position: relative;
  padding: 55px 0 0 0;
  display: inline-block;
  vertical-align: top; }
  .content_group li span {
    display: inline-block;
    font-size: 12px;
    color: #000;
    line-height: 1.3; }
  .content_group li:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }

.content_group .top_section {
  position: relative; }

.content_group .bottom_section {
  padding: 35px 0; }

.content_group .btn_group {
  width: 100%;
  padding-bottom: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center; }
  .content_group .btn_group .btn_signup {
    width: 210px;
    display: block;
    margin: 0 auto 14px;
    font-weight: 500;
    font-size: 14px; }
  .content_group .btn_group .btn_login {
    font-size: 13px;
    color: #ffdce3;
    text-decoration: none; }
    .content_group .btn_group .btn_login strong {
      display: inline-block;
      border-bottom: 1px solid;
      font-weight: 400;
      color: #2bde73;
      cursor: pointer;
      line-height: 1.2; }

.content_group .img_area img {
  width: 100%;
  height: auto;
  display: block; }

.content_group .real_free:before {
  background-image: url(../images/sprite/common.png);
  background-position: -675px -1190px;
  width: 48px;
  height: 45px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .content_group .real_free:before {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.content_group .real_event:before {
  background-image: url(../images/sprite/common.png);
  background-position: -738px -1190px;
  width: 48px;
  height: 45px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .content_group .real_event:before {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.content_group .real_save:before {
  background-image: url(../images/sprite/common.png);
  background-position: -801px -1190px;
  width: 48px;
  height: 45px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .content_group .real_save:before {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

@media (max-width: 620px) {
  .leave .check_list {
    margin-top: 42px; }
  .leave h3 {
    font-size: 23px; } }

.marketing-agreement {
  display: flex; }
  .marketing-agreement label {
    font-size: 14px;
    font-weight: 500;
    color: #585858; }
    .marketing-agreement label a {
      text-decoration: underline;
      color: #585858; }
  .marketing-agreement input:checked + label .fake_checkbox {
    background: url("https://cf.realclass.co.kr/qualson/images/d6824e6a2de6323077d66ce79d40bf1e");
    background-size: 100% 100%; }
  .marketing-agreement .fake_checkbox {
    vertical-align: middle;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    background: url("https://cf.realclass.co.kr/qualson/images/e2a9bb15f44bff67f8bcce1d73821521");
    background-size: 100% 100%; }

.marketing-text {
  margin-top: 22px;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  color: #707276; }
